import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from "../../baseQuery";
import { showToast } from "../Notifications/notificationsSlice";

export interface Queue {
    id: string | null;
    name: string;
    ringStrategy?: string;
}

interface CreateQueueRequest {
    name: string;
    ringStrategy?: string;
}

export const callQueueApi = createApi({
    reducerPath: 'callQueueApi',
    baseQuery,
    tagTypes: ['Queue'],
    endpoints: (builder) => ({

        getQueues: builder.query<Queue[], void>({
            query: () => ({
                url: '/api/v1/queues',
                method: 'GET',
            }),
            transformResponse: (response: { data: Queue[] }) => response.data,
            providesTags: ['Queue'],
        }),

        getQueueById: builder.query<Queue, number>({
            query: (id) => ({
                url: `/api/v1/queues/${id}`,
                method: 'GET',
            }),
            providesTags: (_result, _error, id) => [{ type: 'Queue', id }],
        }),

        createQueue: builder.mutation<Queue, CreateQueueRequest>({
            query: (data) => ({
                url: '/api/v1/queues',
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['Queue'],
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    dispatch(showToast({ description: 'Call queue created successfully!', type: 'success' }));
                } catch {
                    dispatch(showToast({ description: 'Failed to create call queue!', type: 'error' }));
                }
            },
        }),

        updateQueue: builder.mutation<Queue, { id: number; data: Partial<CreateQueueRequest> }>({
            query: ({ id, data }) => ({
                url: `/api/v1/queues/${id}`,
                method: 'PATCH',
                body: data,
            }),
            invalidatesTags: (_result, _error, { id }) => [{ type: 'Queue', id }, 'Queue'],
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    dispatch(showToast({ description: 'Call queue updated successfully!', type: 'success' }));
                } catch {
                    dispatch(showToast({ description: 'Failed to update call queue!', type: 'error' }));
                }
            },

        }),

        deleteQueue: builder.mutation<{ success: boolean }, number>({
            query: (id) => ({
                url: `/api/v1/queues/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: (_result, _error, id) => [{ type: 'Queue', id }, 'Queue'],
        }),
    }),
});

export const {
    useGetQueuesQuery,
    useGetQueueByIdQuery,
    useCreateQueueMutation,
    useUpdateQueueMutation,

} = callQueueApi;
