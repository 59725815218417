import {ChSearch, ChTooltip, Icon} from "ch3-ui-lib";
import {Queue, useGetQueuesQuery} from "../../../store/features/CallQueue/CallQueueService";
import {selectorItem} from "../../../utilis/types/Types";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";

export interface CallQueueModalProps {
    setQueue: Function;
}

export const CallQueueModal = ({setQueue}: CallQueueModalProps) => {

    const role = useSelector((state: any) => state.auth.role);
    const handlePhraseChange = (phrase: string) => {
        list.filter((item) => item.label?.toLowerCase().includes(phrase.toLowerCase()));
    }
    const {data: callQueue = []} = useGetQueuesQuery();

    const list: selectorItem[] = callQueue
        ?.map((queue: Queue) => ({
            label: queue.name,
            value: queue.id?.toString() ?? '',
            key: queue.id?.toString() ?? '',
        }))
        .filter((item) => item.value !== '' && item.key !== '');

    return (
        <div className='w-[400px]'>
            <div>
                The call will be routed to the appropriate queue based on predefined criteria.
            </div>
            <div  className='my-4'>
                <ChSearch
                          onInputChange={handlePhraseChange}
                          onChange={(item) => setQueue(Number(item.value))}
                          type={'text'} disabled={false}
                          value={''}
                          prefixIcon=''
                          fit
                          label='Choose queue'
                          placeholder='Search'
                          items={list}
                />
                {role === 'ADMIN' &&  <div className={'text-end mb-8 text-sm'}>
                    Can't find queue? <span className='text-primary-400 cursor-pointer'><Link to={'/admin/callQueues/new'}>Create a new queue</Link> </span>
                </div>}
                {role !== 'ADMIN'  && !list.length &&  <div className={'text-end mb-8 justify-end flex gap-3'}>
                    <div className={'flex gap-3 text-sm'}>
                        No queues available for you.
                    </div>
                    <ChTooltip
                        theme={'dark'}
                        content={"You don't have permission to create a new queue. Please contact your  admin." }
                        trigger={
                        <div>
                            <Icon background={true} iconName='question_mark' className={'bg-black text-white'} size={'xxs'} />
                        </div>
                    }>
                    </ChTooltip>

                </div>}
            </div>

        </div>


    );
}
