import {Button, ChInput, ChRadio, Loader} from "ch3-ui-lib";
import {ClosedDialerMargin, OpenDialerMargin} from "../../utilis/dialerWidthUtils";
import {RootState} from "../../store/store";
import {useSelector} from "react-redux";
import {
    useCreateQueueMutation,
    useGetQueueByIdQuery,
    useUpdateQueueMutation
} from "../../store/features/CallQueue/CallQueueService";
import { useForm, Controller } from "react-hook-form";
import {useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";

const routingOptions = [
    {
        id: '1',
        label: "Ring all",
        description: "Call all agents at once. First to answer handles the call.",
        value: "RING_ALL",
    },
    {
        id: '2',
        label: "Longest Idle",
        description: "Call the agent idle the longest, considering tiers.",
        value: "LONGEST_IDLE_AGENT",
    },
    {
        id: '3',
        label: "Random",
        description: "Call agents in random order.",
        value: "RANDOM",
    }
];

export const CallQueues = () => {

    const { id } = useParams<{ id: string }>();
    const isNewQueue = id === 'new';
    const isDialerHidden= useSelector((state: RootState) => state.dialer.isDialerHidden);
    const {data: callQueue, isLoading: isLoading} = useGetQueueByIdQuery(Number(id), {skip: isNewQueue});
    const [createQueue] = useCreateQueueMutation();
    const [updateQueue] = useUpdateQueueMutation();
    const {
        control,
        handleSubmit,
        setValue,
        watch,
        formState: { errors },
    } = useForm({
        mode: 'onBlur',
        defaultValues: {
            name: '',
            ringStrategy: 'ring_all',
        },
    });
    const navigate = useNavigate();


    useEffect(() => {
        if (callQueue) {
            setValue('name', callQueue.name || '');
            setValue('ringStrategy', callQueue.ringStrategy || 'RING_ALL');
        }
    }, [callQueue, setValue]);

    const onSubmit = async (data: any) => {
        if (isNewQueue) {

            await createQueue(data);
            navigate('/admin/callQueues');
        } else {
            await updateQueue({id: Number(id), data});
            navigate('/admin/callQueues');

        }
    }

    if(isLoading) {
        return (
            <div className='flex w-full'>
                <Loader/>
            </div>
        );
    }
    return (

        <div className={`flex flex-col w-full ${isDialerHidden ? ClosedDialerMargin : OpenDialerMargin}`}>
            <div className="flex flex-col w-full max-md:max-w-full">
                <div className="flex flex-wrap gap-8 pl-8 w-full border-b border-solid border-b-black max-md:pl-5">
                    <div className="flex flex-justify-center py-5   w-[280px]">
                        <div className="flex flex-col w-full">
                            <div className="text-sm font-medium leading-none text-zinc-950 max-md:max-w-full">
                                <label htmlFor="queueName" className="text-base font-semibold tracking-tight text-black">
                                    Queue name
                                </label>
                            </div>
                            <div className="mt-1.5 text-sm leading-5 text-neutral-500">
                                Enter a name to identify this call queue (e.g., Support Line, Sales Queue)
                            </div>

                        </div>
                    </div>
                    <div className="flex flex-col grow shrink justify-center self-start p-6 border-l border-solid border-l-zinc-200 border-zinc-200 min-w-[240px]">
                        <div className="flex flex-col max-w-full min-h-[68px] w-[696px]" >
                            <Controller
                                name="name"
                                control={control}
                                rules={{ required: 'Name is required' }}
                                render={({ field }) => (
                            <ChInput
                                label={'Name'}
                                placeholder="Enter queue name"
                                value={field.value}
                                callback={(value:any) => field.onChange(value)}
                                error={errors.name ? errors.name.message : undefined}
                                className="w-full" />
                            )}
                            />

                        </div>
                    </div>
                </div>
                <div className="flex flex-wrap gap-8 pl-8 w-full border-b border-solid border-b-black max-md:pl-5 max-md:max-w-full">
                    <div className="flex flex-col justify-center py-5 min-w-[240px] w-[280px]">
                        <div className="flex flex-col w-full">
                            <div className="text-base font-semibold text-black">
                                Call routing strategy
                            </div>
                            <div className="mt-1.5 text-sm leading-5 text-neutral-500">
                                Select how calls are routed to agents in this queue.
                            </div>
                        </div>
                    </div>
                    <div className="flex overflow-hidden flex-col flex-1 shrink self-start border-l border-solid basis-0 border-l-zinc-200 border-zinc-200 min-w-[240px] max-md:max-w-full">
                        <div className="flex flex-col justify-center p-6 w-full max-md:px-5 max-md:max-w-full">
                            <div className="flex flex-col justify-center w-full max-md:max-w-full">
                                <ChRadio
                                    alignLeft={true}
                                    value={watch('ringStrategy')}
                                    callback={(value: string) => {
                                    setValue('ringStrategy', value)
                                }}  className={'flex-col'}  radioGroup={routingOptions} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='flex justify-end gap-5 m-5'>
                <Button buttonType={'secondary'}  onClick={() => navigate('/callQueues')} label={'Discard changes'}/>
                <Button buttonType={'primary'} onClick={handleSubmit(onSubmit)} label={'Save queue'}/>
            </div>
        </div>
    );
};
