import { IntegrationDescription } from "./IntegrationDescription";
import { useParams } from "react-router-dom";
import useMediaQuery from "../../utilis/screenWidthUtils";
import { Button } from "ch3-ui-lib";
import google from "../../assets/google.svg";
import { IntegrationGoogle } from "./Google/IntegrationGoogle";
import { UserList } from "./Users/UserList";
import {useNavigate} from 'react-router-dom';
import {
    GoogleIntegrationResponse,
    useStartIntegrationGoogleMutation
} from "../../store/features/integrations/integrationsService";

type IntegrationKey = "google";

interface SyncStep {
    integrationName: string;
    title: string;
    helpDescription: string;
    buttonLabel: string;
    startText: string;
    idText: string;
    scopeText: string;
    scopeCopyText: string;
    description_1:string;
    description_2:string;
    description_3: string;
    description_4: string;
    attentionDescription: string;
    instructionText: string;
    adminPanelText: string;
    adminPanelLink: any;
    id: string;
}

const syncSteps: Record<IntegrationKey, SyncStep> = {
    google: {
        startText: "On this screen, you will find information necessary to start an integration process of your company’s PhoneHQ account with Google Workspace. Please ensure you have admin rights for your Google Workspace account before proceeding. You will also need the following information:",
        idText: "Your unique PhoneHQ Client ID",
        id: "114628677700680530213",
        scopeText: "Scope of the integration",
        scopeCopyText: "https://www.googleapis.com/auth/admin.directory.user.readonly,https://www.googleapis.com/auth/admin.directory.orgunit.readonly,https://www.googleapis.com/auth/admin.directory.group.readonly,https://www.googleapis.com/auth/admin.reports.audit.readonly",
        integrationName: "Google Contacts",
        title: "Integration steps:",
        description_1:
            'Access your',
        adminPanelText:
            'Google Workspace Admin Panel',
        adminPanelLink:
            'https://admin.google.com/ac/owl/domainwidedelegation',
        description_2:
            'In the API Permissions section, input the Client ID and integration scope provided above.',
        description_3:
            'Confirm that the integration is listed as active in Google Workspace.',
        description_4:
            'Return to this screen and proceed to the next step to complete the setup.',
        attentionDescription:
            "Proceed to the next step only if you have successfully completed the instructions above!",
        helpDescription:
            "If you encounter any issues during the process, please contact PhoneHQ Support or Google Workspace Support.",
        buttonLabel: "I've completed all the steps, proceed",
        instructionText: "You can also check detailed instruction in our Knowledge Base Article."
    },
};

export const Integration = () => {

    const { id, step } = useParams<{ id?: IntegrationKey; step?: "info" | "connect" | "list" }>();
    const isMobile = useMediaQuery("(max-width: 960px)");

    const navigate = useNavigate();
    const [startIntegrationGoogle, { data, isLoading}] = useStartIntegrationGoogleMutation();


    const steps: Record<IntegrationKey, Record<string, JSX.Element>> = {
        google: {
            info: <IntegrationDescription {...syncSteps["google"]} />,
            connect: <IntegrationGoogle  startIntegrationGoogle={startIntegrationGoogle} isLoading={isLoading}  />,
            list: <UserList integration={data as GoogleIntegrationResponse}/>,
        },
    };

    const currentId = id ?? "google";
    const currentStep = step ?? "info";

    return (
        <div className={`w-full `}>
            <div className="flex gap-4 items-center  pt-8 px-8"  onClick={() => navigate(-1)}>
                {currentId == 'google' && <img src={google} alt="Google Logo"/>}
                <Button size="small" className="pl-1" buttonType="secondary" rightIcon="arrow_back_ios" />
                <div className={"font-bold text-xl"}>{syncSteps[currentId].integrationName}</div>
            </div>
            <div
                className={
                    ` p-8 pt-10 flex ` +
                    `${isMobile ? "px-4 flex-col" :  " flex-row" } ` +
                    " flex gap-8 items-start "
                }
            >
                {steps[currentId][currentStep]}
            </div>
        </div>
    );
};
