import {Button, ChInput, Icon} from "ch3-ui-lib";
import {Controller, useForm} from "react-hook-form";
import {validateEmail} from "../../../utilis/commonValidators";
import { useNavigate } from "react-router-dom";
import {useState} from "react";

interface IntegrationGoogleProps {
    startIntegrationGoogle: Function;
    isLoading: boolean;

}

export const IntegrationGoogle = ({startIntegrationGoogle, isLoading}: IntegrationGoogleProps) => {
    const [error, setError] = useState('');

    const navigate = useNavigate();

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        mode: 'onBlur',
        defaultValues: {
            email: '',
           },
    });



    const onSubmit = async (data: any) => {
        try {
            await startIntegrationGoogle({ email: data.email }).unwrap();
            navigate('/admin/integrations/google/list');
        } catch (err: any) {
            setError(err?.data?.message || 'Failed to connect Google account. Please try again.');
        }
    };
    return (
        <main className='mx-auto max-w-[580px]'>
            {error && <div className="flex flex-wrap gap-4 items-start p-4 bg-rose-100 rounded-sm border border-red-600 border-solid shadow-sm max-w-[577px] mx-auto mb-8" role="alert">
              <section
                    className="flex flex-wrap flex-1 shrink gap-4 items-start basis-0 min-w-[240px] max-md:max-w-full">
                    <Icon size='lg' iconName='error' className='text-red-500'/>
                    <div className="flex flex-col flex-1 shrink justify-center basis-0 min-w-[240px] max-md:max-w-full">
                        <h2 className="text-base font-semibold tracking-tight text-black max-md:max-w-full">
                            Error
                        </h2>
                        <p className="mt-1 text-sm leading-none text-zinc-950 max-md:max-w-full">
                            {error}
                        </p>
                    </div>
                </section>
            <button
                onClick={()=> setError('')}
                className="flex gap-2 justify-center w-5 min-h-[20px]"
                aria-label="Close notification"
            >
                <Icon size='lg' iconName='close' className='text-black'/>
            </button>
        </div>}
                <h2 className="text-2xl font-bold leading-none">Connect Your Google Account</h2>
                <p className="mt-4 text-base leading-6 mb-8">
                    Enter your Google email to sync contacts with phoneHQ and keep your address book up-to-date.
                </p>
            <Controller
                name="email"
                control={control}
                rules={{
                    validate: {
                        isValid: (value:any )=> value ? ( validateEmail(value) || 'Invalid email format') : true,
                    },
                }}
                render={({ field }) => (
                    <ChInput
                        {...field}
                        value={field.value}
                        callback={(value:any) => field.onChange(value)}
                        className='max-w-full p-4 mb-8'

                        label='Email address'
                        error={errors.email ? errors.email.message : undefined}
                    />
                )}
            />

            <Button loading={isLoading} onClick={handleSubmit(onSubmit)} label={'Begin Sync'} buttonType={'primary'} className='ml-auto mt-4 min-w-[140px]' />

        </main>
    );
}
