import {Icon} from "ch3-ui-lib";
import {Call} from "../store/features/calls/callsService";


export const getCallType = (callType: string, bridgetAt:string, forwarded: any) => {
    if (forwarded) {
        return <Icon  className="p-0 w-7 h-7" iconName='phone_forwarded' />;
    }
    else if (!forwarded  &&!bridgetAt) {
        switch (callType) {
            case 'OUTBOUND':
                return <Icon className='text-error-400 p-0 w-7 h-7' iconName='call_made'  />;
            case 'INBOUND':
                return <Icon  className='text-error-400 p-0 w-7 h-7' iconName='call_missed' />;
            default:
                return null;
        }
    } else {
        switch (callType) {
            case 'OUTBOUND':
                return <Icon className="p-0 w-7 h-7 text-grey-400" iconName='call_made' />;
            case 'INBOUND':
                return <Icon className="p-0 w-7 h-7 text-grey-400" iconName='call_received' />;
            default:
                return null;
        }
    }
};


export const getCallTypeDescription = (callType: string, bridgetAt:string, forwardedTo:any) => {
    if (!bridgetAt && !forwardedTo) {
        return 'Missed call';
    } else {
        switch (callType) {
            case 'OUTBOUND':
                return 'Outgoing call';
            case 'INBOUND':
                return 'Incoming call';
            default:
                return null;
        }
    }
};

export function getCallDescription(call: Call | undefined): any {
    if(!call) return  'Unknown call type';
    if (call.forwardedTo) {
        return (
            <div className="flex flex-col">
                <p className="mr-2">Call forwarded to </p>
            </div>);
    }

    if (call.type === 'OUTBOUND' && call.bridgetAt) {
        return 'Outgoing call';
    }
    if (call.type === 'OUTBOUND' && !call.bridgetAt) {
        return 'Missed outgoing call';
    }
    if (call.type === 'INBOUND' && !call.bridgetAt) {
        return 'Missed incoming call';
    }
    if (call.type === 'INBOUND' && call.bridgetAt) {
        return 'Incoming call';
    }
    return 'Unknown call type';
}
