import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {Avatar, Button, ChBadge, ChDropdown, ChTabs, Datepicker, Icon, Loader} from 'ch3-ui-lib';
import {useIntl} from 'react-intl';
import {groupCallsByDate} from '../../utilis/timeUtilis';
import {RootState, useAppDispatch} from '../../store/store';
import RecentCalls from './RecentCalls';
import MissedCalls from './MissedCalls';
import {
    initialState,
    setContactsFilter, setLimit,
    setPhoneNumbersFilter, setSelectedDates,
    setUsersAndGroupsFilter
} from "../../store/features/Filters/filtersSlice";
import {getFormatedPhoneNumber} from "../../utilis/msisdnUtilis";
import useMediaQuery from "../../utilis/screenWidthUtils.ts";
import {
    Call,
    useFetchHistoryListQuery
} from "../../store/features/calls/callsService";
import {Contact, ContactSelectItem, useGetContactsQuery} from "../../store/features/Contacts/contactsService";
import {
    MsisdnSelectItem,
    useGetUserMsisdnsQuery
} from "../../store/features/Msisdns/msisdnService";
import {buildQueryParams} from "../../utilis/buildQueryParams";
import {useFetchUsersQuery} from "../../store/features/Users/userService";
import {useFetchGroupsQuery} from "../../store/features/groups/groupsService";
import {selectUserId} from "../../store/features/Auth/authSlice";


export default function Calls() {
    const dispatch = useAppDispatch();
    const intl = useIntl();

    const [phonePhrase, setPhonePhrase] = useState<string>('');
    const [contactPhrase] = useState<string>('');
    const [userGroupPhrase] = useState<string>('');

    const [groupedCalls, setGroupedCalls] = useState<{ [key: string]: Call[] }>({});
    const [missedCalls, setMissedCalls] = useState<{ [key: string]: Call[] }>({});

    const isMobile = useMediaQuery('(max-width: 960px)');
    const filterIcon = <Icon iconName='filter_list ' className='text-primary-400'></Icon>

    const {
        phoneNumbersFilter,
        contactsFilter,
        usersAndGroupsFilter,
        selectedDates,
        limit,
    } = useSelector((state: RootState) => state.filters);


    const userId = useSelector(selectUserId);


    const callsQueryParams = buildQueryParams({
        limit,
        contacts: contactsFilter.map((contact: any) => contact.contactId),
        phoneNumbers: phoneNumbersFilter.map((item: any) => item.msisdn),
        groups: usersAndGroupsFilter.filter((item: any) => item.groupId).map((item: any) => item.groupId),
        users: usersAndGroupsFilter.filter((item: any) => item.userId).map((item: any) => item.userId),
        from: selectedDates.from,
        to: selectedDates.to,
        userId,
    });
    const contacstsQueryParams = buildQueryParams({
        userId,
        phrase: contactPhrase,
    });
    const msisdnQueryParams = buildQueryParams({
        phrase: phonePhrase,
        userId,
    });
    const userGroupQueryParams = buildQueryParams({
        phrase: userGroupPhrase,
    });
    const groupQueryParams = buildQueryParams({
        phrase: userGroupPhrase,
        userId,
    });


    const {data: usersData = [], isLoading: isLoadingUsers} = useFetchUsersQuery(userGroupQueryParams);
    const {data: groupsData, isLoading: isLoadingGroups} = useFetchGroupsQuery(groupQueryParams);
    // const {
    //     data: inboundQueueCalls,
    //     isLoading: isLoadingInboundQueueCalls
    // } = useFetchInboundQueueCallQuery(userId, {pollingInterval: 5000});
    const {data: userMsisdns, isLoading: isLoadingMsisdns} = useGetUserMsisdnsQuery(msisdnQueryParams);
    const {
        data: historyList,
        isLoading: isLoadingHistory
    } = useFetchHistoryListQuery(callsQueryParams, {pollingInterval: 15000});
    const {data: userContacts, isLoading: isLoadingContacts} = useGetContactsQuery(contacstsQueryParams);

    const recentCallsCount = historyList ? historyList.totalCount : 0;

    const isLoading =  isLoadingMsisdns || isLoadingHistory || isLoadingContacts || isLoadingGroups || isLoadingUsers;

    const filterGroup = ( ) => {
        return  [
              {
                  items: [
                      {
                          key: 'phoneNumber',
                          label: 'Phone numbers',
                          subItems: [{items: phoneNumbers}]
                      },
                      {
                          key: 'contacts',
                          label: 'Contacts',
                          subItems: [{items: contacts}]
                      },
                      {
                          key: 'usersAndGroups',
                          label: 'Users ',
                          subItems: dropdownGroupUsersAndGroups,
                      },
                      {
                          key: 'date',
                          label: 'Date ',
                          subItems: <Datepicker  type={'rangeWithoutTrigger'} onDateConfirm={handleDateSelect}/>
                      }
                  ],
                  type: 'checkbox',
                  callback: (item: any,  key: any) => {

                      if(key === 'phoneNumber') {
                          handlePhoneNumberSelect(item)
                          return;
                      }
                      if(key === 'contacts') {
                          handleContactSelect(item)
                          return;
                      }
                      if(key === 'usersAndGroups') {
                          handleUserAndGroupSelect(item)
                          return;
                      }
                      if(key === 'date') {
                          handleDateSelect(item)
                          return;
                      }
                  }
              }
          ]
      }

      const getFiltersLength = () => {
        let filtersLength = contactsFilter.length + phoneNumbersFilter.length + usersAndGroupsFilter.length
          if ((selectedDates.from !== initialState.selectedDates.from) || (selectedDates.to !== initialState.selectedDates.to) ) {
              filtersLength = filtersLength + 1
          }
          return filtersLength
      }

    useEffect(() => {
        if (historyList?.calls) {
            setGroupedCalls(groupCallsByDate(historyList.calls, intl) ?? {});
            setMissedCalls(
                groupCallsByDate(
                    historyList.calls.filter((call: Call) => call.type === 'INBOUND' && !call.bridgetAt),
                    intl
                ) ?? {}
            );
        }
    }, [historyList]);

    const phoneNumbers = userMsisdns?.data?.map((msisdn: any) => (
        {
            label: <div className='flex items-center'>
            <div className='mr-2'>
            <Avatar icon={msisdn.icon} size="sm" type='icon' color={msisdn.iconColor}
                    name='phone number icon'/>
            </div>
            {msisdn.label}</div>,
            value: msisdn,
            key: msisdn.msisdnId
        }
    ));

    const contacts = userContacts?.contacts ? userContacts?.contacts?.concat(contactsFilter)
        .reduce<{ map: Map<number, boolean>; result: any }>((acc: any, contact: any) => {
            if (!acc.map.has(contact.contactId)) {
                acc.map.set(contact.contactId, true);
                acc.result.push({
                    key: contact.contactId,
                    label: contact.firstName + ' ' + contact.lastName,
                    description: getFormatedPhoneNumber(contact.phoneNumber),
                    hideDescription: true,
                    value: contact,
                    prefixElement: <div className='mx-2'>
                        <Avatar size="xs" type='initials' color={contact.iconColor ?? 'auto'}
                                name={contact.firstName + ' ' + contact.lastName}/>
                    </div>,
                    type: 'checkbox'
                });
            }
            return acc;
        }, {map: new Map(), result: []})
        .result : [];

    const users = usersData?.map((user) => ({
        key: `user${user.userId}`,
        label: user.firstName + ' ' + user.lastName,
        value: user,
        prefixElement: <div className='mx-2'><Avatar size="xs" name={user.firstName + ' ' + user.lastName}
                                                     type='initials' color={user.iconColor ?? 'auto'}/></div>,
    }));

    const groups = groupsData?.groups ? groupsData?.groups?.map((el: any) => ({
        key: `group${el.group.groupId}`,
        label: el.group.name,
        value: el.group,
        prefixElement: <div className='mx-2'><Avatar size="xs" type='icon' color={el.group.iconColor ?? 'auto'}
                                                     icon={el.group.icon ?? 'home'}/></div>,
        type: 'checkbox'
    })) : [];


    const handleContactSelect = (value: ContactSelectItem) => {
        const exists = contactsFilter.some((item: Contact) => item?.contactId === value.contactId);
        const newContactsFilter = exists
            ? contactsFilter.filter((item: Contact) => item.contactId !== value.contactId)
            : [...contactsFilter, value];

        dispatch(setContactsFilter(newContactsFilter));
    };

    const handleUserAndGroupSelect = (value: any) => {

        const exists = usersAndGroupsFilter.some((item: any) => {
            const userIdMatches = item?.userId && value?.userId && item.userId === value.userId;
            const groupIdMatches = item?.groupId && value?.groupId && item.groupId === value.groupId;
            return userIdMatches || groupIdMatches;
        });

        const newUsersAndGroupsFilter = exists
            ? usersAndGroupsFilter.filter((item: any) => item.userId != value?.userId || item?.groupId != value?.groupId)
            : [...usersAndGroupsFilter, value];

        dispatch(setUsersAndGroupsFilter(newUsersAndGroupsFilter));
    }

    const handlePhoneNumberSelect = (value: MsisdnSelectItem) => {
        const exists = phoneNumbersFilter.some((item: any) => item.msisdnId === value.msisdnId);
        const newPhoneNumbersFilter = exists
            ? phoneNumbersFilter.filter((item: any) => item.msisdnId !== value.msisdnId)
            : [...phoneNumbersFilter, value];

        dispatch(setPhoneNumbersFilter(newPhoneNumbersFilter));
    }

    const handleDateSelect = (value: any) => {

        const from = new Date(value[0].valueOf()).toISOString();
        const to = new Date(value[1].valueOf()).toISOString();
        dispatch(setSelectedDates({ from, to }));
    }

    const handleBottomReach = () => {
        if (recentCallsCount > limit) {
            dispatch(setLimit(limit + 50));
        }
    }

    const tabs = [
        {
            id: '1',
            label: intl.formatMessage({id: 'calls.recent'}),
            content: groupedCalls ? <RecentCalls  recentCallsCount={recentCallsCount} reachedEnd={handleBottomReach} groupedCalls={groupedCalls ?? []}/> :
                <div>loading...</div>
        },
        // {
        //     id: '2',
        //     label: intl.formatMessage({id: 'calls.inboundQueue.name'}),
        //     content: <InboundCallQueue isMobile={isMobile} callQueue={inboundQueueCalls ?? []}/>
        // },
        {
            id: '3',
            label: intl.formatMessage({id: 'calls.missed'}),
            content: <MissedCalls groupedCalls={missedCalls ?? {}}/>
        },
    ];

    const dropdownGroupUsersAndGroups = [
        {
            title: 'Users',
            items: users ?? [],
            callback: handleUserAndGroupSelect,
            type: 'checkbox'
        },
        {
            title: 'Groups',
            items: groups ?? [],
            callback: handleUserAndGroupSelect,
            type: 'checkbox'
        }
    ];
    if (isLoading) {
        return (
            <div className={`flex w-full `}>
                <Loader/>
            </div>
        );
    }
    return (
        <>
            {!isLoading && (

                <div className={`w-full h-full` }>
                    <div className={`${isMobile ? ' px-[16px]' :   'px-0 h-full'}`}>
                        <div className={` flex  flex-col h-full`}>

                            <ChTabs className="px-8 py-3 h-[60px]"  activeTabId={tabs[0].id} tabs={tabs}>
                                <ChDropdown trigger={filterIcon}
                                            className={'p-4'}
                                            type={'sub-search'}
                                            onPhraseChange={(value: string) => setPhonePhrase(value)}
                                            dropdownGroup={ filterGroup() ?? []}
                                            selected={[...phoneNumbersFilter, ...contactsFilter, ...usersAndGroupsFilter]}></ChDropdown>
                                {
                                    Boolean(getFiltersLength()) && <ChBadge label={getFiltersLength().toString()}  badgeColor={'purple'} size={'sm'} />
                                }
                                {Boolean(getFiltersLength()) && <Button
                                    size='small'
                                    label='Clear filters'
                                    buttonType='textSecondary'
                                    rightIcon='close'
                                    onClick={() => {
                                        dispatch(setPhoneNumbersFilter([]));
                                        dispatch(setContactsFilter([]));
                                        dispatch(setUsersAndGroupsFilter([]));
                                        dispatch(setSelectedDates({from: initialState.selectedDates.from, to: initialState.selectedDates.to}));
                                    }}/>}

                            </ChTabs>

                            </div>
                    </div>
                </div>
            )}
        </>
    );
}
