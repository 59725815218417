import {hexToEmoji} from "../Emoji/emoji";
import EmojiPicker, {EmojiStyle} from "emoji-picker-react";
import {Button, ChDropdown, ChInput, Datepicker, Icon, Timepicker} from "ch3-ui-lib";
import {useEffect, useRef, useState} from "react";
import {convertFromISOFormat, convertToISOFormat} from "../../utilis/timeUtilis";
import moment  from 'moment-timezone';
import {useChangeStatusMutation} from "../../store/features/Team/teamService";

interface SetStatusProps {
    setStatusRef: any;
    currentUser: any;
    refetch: any;
}

export const SetStatusModal = ({ setStatusRef, currentUser, refetch}:SetStatusProps) => {

    const [useChangeStatus] = useChangeStatusMutation();


    const checkStatusExpiration = () => {
        if (currentUser?.status?.customUserStatus?.dueDate === null && currentUser?.status?.customUserStatus?.name) {
            return false;
        }
        if (currentUser?.status?.customUserStatus?.dueDate) {
            const now = new Date();
            const dueDate = new Date(currentUser?.status?.customUserStatus?.dueDate);
            return (now > dueDate);
        } else {
           return true;
        }
    };
    const isExpired = checkStatusExpiration();

    const { dateString, timeString } =  convertFromISOFormat(currentUser.status.customUserStatus?.dueDate || '');

    const displayString = dateString ? `${dateString}, ${timeString}` : timeString;

    const getStatusExpiration = () => {
        if (isExpired) {
            return '';
        }
        else if (currentUser?.status?.customUserStatus?.dueDate === null && currentUser?.status?.customUserStatus?.name) {
            return 'Don\'t clear';
        }
        return  `Expires: ${displayString}`;
    }

    const [emojiPickerOpen, setEmojiPickerOpen] = useState(false);
    const pickerRef = useRef<HTMLDivElement>(null);
    const [selected, setSelected] = useState<any>([getStatusExpiration()]);
    const [selectedHour, setSelectedHour] = useState(timeString || '');
    const [selectedDate, setSelectedDate] = useState<any>(dateString || moment().format("MMM DD, YYYY"));

    const [isModified, setIsModified] = useState(true);


    const roundToNextQuarterHour = (time: any) => {
        const minutes = time.minutes();
        const roundedMinutes = Math.ceil(minutes / 15) * 15;
        if (roundedMinutes === 60) {
            time.add(1, 'hour').startOf('hour');
        } else {
            time.minutes(roundedMinutes);
        }
        return time.seconds(0).milliseconds(0);
    };

    useEffect(() => {
        const roundedTime = roundToNextQuarterHour(moment().add(30, 'minutes'));
        const formattedTime = roundedTime.format("h:mm A");
        setSelectedHour(formattedTime);
    }, []);


    const [formData, setFormData] = useState({
        name: isExpired ? '' : currentUser.status.customUserStatus?.name || '',
        icon: isExpired ? '1F4C5' : currentUser.status.customUserStatus?.icon || '1F4C5',
        dueDate: isExpired ? '' : currentUser.status.customUserStatus?.dueDate || '',
    });

    useEffect(() => {
        const { name, icon, dueDate } = formData;
        const { customUserStatus } = currentUser.status || {};

        if(!formData.name || !formData.icon || !formData.dueDate) {

            setIsModified(true);
            return;
        }
        if (name && (name !== customUserStatus?.name || icon !== customUserStatus?.icon || dueDate !== customUserStatus?.dueDate)) {
            setIsModified(true);
        } else {
            setIsModified(false);
        }
    }, [formData, currentUser.status.customUserStatus]);

    const handleChange = (value: string) => {
        setFormData((prev) => ({...prev, name: value}));
    }
    const openEmojiPicker = (e: any) => {
        setEmojiPickerOpen(!emojiPickerOpen);
        e.stopPropagation();

    }

    const handlePickEmote = (val:any) => {
        const icon = val.unified;
        setFormData((prev) => ({...prev, icon: icon}));
        setEmojiPickerOpen(false);

    }

    const handleSelect = (value: any) => {
        switch (value) {
            case 'Don\'t clear':
                setFormData((prev) => ({
                    ...prev,
                    dueDate: null
                }));
                setSelected([value]);
                break;
            case 'Today':
                setFormData((prev) => ({
                    ...prev,
                    dueDate: moment().endOf('day').format("YYYY-MM-DDTHH:mm:ss.SSSZ")
                }));
                setSelected([value]);
                break;
            case '30 minutes':
                setFormData((prev) => ({
                    ...prev,
                    dueDate: moment().add(30, 'minutes').format("YYYY-MM-DDTHH:mm:ss.SSSZ")
                }));
                setSelected([value]);
                break;
            case '1 hour':
                setFormData((prev) => ({
                    ...prev,
                    dueDate: moment().add(1, 'hour').format("YYYY-MM-DDTHH:mm:ss.SSSZ")
                }));
                setSelected([value]);
                break;
            case '4 hours':
                setFormData((prev) => ({
                    ...prev,
                    dueDate: moment().add(4, 'hours').format("YYYY-MM-DDTHH:mm:ss.SSSZ")
                }));
                setSelected([value]);
                break;
            case 'This week':
                const endOfWeek = moment().clone().endOf('week').add(24,'hour').format("YYYY-MM-DDTHH:mm:ss.SSSZ");
                setFormData((prev) => ({
                    ...prev,
                    dueDate: endOfWeek
                }));
                setSelected([value]);
                break;
            case 'Choose date and time':
                setSelected([value]);
                break;
            default:
                break;
        }
    };
    const quickChoices = () => {

        return [
            {
                icon: '231B',
                name: 'Be right back',
                time: '15 minutes',
                dueDate: moment().add(15, 'minutes').format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            },
            {
                icon: '1F957',
                name: 'Lunch time',
                time: '30 minutes',
                dueDate: moment().add(30, 'minutes').format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            },
            {
                icon: '1F4C5',
                name: 'Meeting',
                time: '1 hour',
                dueDate: moment().add(60,'minutes').format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            },
            {
                icon: '1f3dd-fe0f',
                name: 'Out of office',
                time: 'Today',
                dueDate: moment().endOf('day').format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
            },
        ];
    };

    const dropdownGroup = () => {
        return [{
            items: [
                {
                    key: '1',
                    label: `Don't clear`,
                    value: `Don't clear`,
                },
                {
                    key: '2',
                    label: '30 minutes',
                    value: '30 minutes',
                },
                {
                    key: '3',
                    label: '1 hour',
                    value: '1 hour',
                },
                {
                    key: '4',
                    label: '4 hours',
                    value: '4 hours',
                },
                {
                    key: '5',
                    label: 'Today',
                    value: 'Today',
                },
                {
                    key: '6',
                    label: 'This week',
                    value: 'This week',
                },
                {
                    key: '7',
                    label: 'Choose date and time',
                    value: 'Choose date and time',
                }
            ],
            callback: (value: any) => {
                handleSelect(value);
            }
        }];
    };

    const handleDateSelect = (value: any) => {

        const date = moment(value.valueOf()).format("MMM DD, YYYY");
        setSelectedDate(date);
        setFormData((prev) => ({
            ...prev,
            dueDate: date
        }));
    }

    const timepickerTrigger = <>


        <ChInput
            className='w-[200px]'
            prefixIcon="calendar_month"
            suffixIcon="keyboard_arrow_down"
            value={selectedDate}
            callback={() => {

            }}
        />
    </>

    const handleSaveMessage = async () => {
        let data
        if(selected[0] === 'Choose date and time') {
             data = {
                name: formData.name,
                icon: formData.icon,
                dueDate: convertToISOFormat(selectedDate, selectedHour),
            }
        } else {
            data = {
                name: formData.name,
                icon: formData.icon,
                dueDate: formData.dueDate,
            }
        }

        await useChangeStatus({data, userId: currentUser.userId});

        refetch();
        setStatusRef.current.onSubmit();

    }
    const clearStatus = async () => {
        await useChangeStatus({
            data: {
                name: '',
                icon: '',
                dueDate: '',
            },
            userId: currentUser.userId
        });
        refetch();
        setStatusRef.current.onSubmit();
    };
    const handleCancel = async () => {
        setStatusRef.current.onSubmit();

    }

    const emojiPicker =      <div className={`cursor-pointer items-center flex  h-[50px] w-[56px] justify-center items-center`}
                                  onClick={(event) => openEmojiPicker(event)}>
        <div className='w-6 h-6' >

            {  hexToEmoji(formData.icon)  }


        </div>

    </div>

     const clearInput = <div onClick={() => {
         setFormData((prev) => ({...prev, name: '', icon: '1F4C5'}));
     }}>
         <Icon iconName='close' className='cursor-pointer hover:text-primary-500' ></Icon>
     </div>

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (pickerRef.current && !pickerRef.current.contains(event.target)) {
                setEmojiPickerOpen(false); // Close the emoji picker
            }
        }

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [pickerRef]);
    return (
        <>
            <div className='text-grey-500 mb-5'>
                Choose action
            </div>
            <div className={'flex mb-2 gap-2 w-[400px]'}>


                <div>
                    <ChInput value={formData.name}
                             prefixElement={emojiPicker}
                             suffixElement={formData.name && clearInput}
                             callback={(val: string) => handleChange(val)}
                             className='max-w-full w-[390px] mt-6'
                             maxLength='160'/>
                </div>
                <div ref={pickerRef} className={'fixed'}>
                    <EmojiPicker emojiStyle={EmojiStyle['TWITTER']} onEmojiClick={(val) => handlePickEmote(val)} open={emojiPickerOpen}/>
                </div>
            </div>
            <div className="text-grey-300 mb-5 align-right">{formData.name.length}/160 characters</div>

            {
                !formData.name && <div className='mb-6'>
                <div className='text-grey-900 px-4 py-2'>
                    QUICK CHOICES
                </div>

                    {
                        quickChoices().map((item) => {
                            return (
                                <div className='flex cursor-pointer' onClick={ () => {
                                    setFormData({
                                        name: item.name,
                                        icon: item.icon,
                                        dueDate: item.dueDate
                                    });
                                    setSelected([item.time]);
                                    refetch();
                                    refetch();
                                }
                                }>
                                    <div  className='flex w-14 justify-center items-center'>
                                        {hexToEmoji(item.icon, 'w-8 h-8')}
                                    </div>
                                    <div>
                                        <div className='font-semibold'>
                                            {item.name}
                                        </div>
                                        <div className='text-grey-500'>
                                            {item.time}
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }


                </div>

            }
            {formData.name && <div className='mb-6'>
                <div>
                    Clear status after...
                </div>
                <ChDropdown dropdownGroup={dropdownGroup()} width={385} selected={selected} type='single'/>
            </div>}
            { selected[0] === 'Choose date and time' &&
                <div className='flex w-[400px] gap-3'>
                <Datepicker type='single' trigger={timepickerTrigger} onDateConfirm={handleDateSelect}/>
                <Timepicker
                    className='w-[180px]'
                    value={selectedHour}
                    onTimeChange={(value: string) => {
                        setSelectedHour(value);
                        setFormData((prev) => ({...prev, dueDate: convertToISOFormat(selectedDate, value)}));
                    }}></Timepicker>
            </div>}
            <div className='pt-8 flex gap-3 justify-end'>

                {isModified &&  <> <Button buttonType='secondary' fit className='mt-5' label='Cancel'
                         onClick={() => handleCancel()}></Button>
                    <Button disabled={(!formData.dueDate && selected[0] !== "Don't clear" )|| !formData.name || !formData.icon} buttonType='primary' fit className='mt-5' label='Save' onClick={() => handleSaveMessage()}></Button></>}
                {

                    !isModified &&  <div className='justify-end'> <Button buttonType='secondary' fit className='mt-5' label='Clear status' onClick={()=> clearStatus()} /></div>
                }
            </div>
        </>



    )
}
