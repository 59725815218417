import React from "react";

export  interface ChDropdownProps {
    trigger: React.ReactNode;
    dropdownGroup: ChDropdownGroup[];
    selected?: string;
}

export interface ChDropdownGroup {
    width?: string;
    title?: string;
    description?: string;
    items: dropdownItem[];
    callback: Function;
    type?: string;
}
export interface dropdownItem
{
    key: string;
    prefixElement?: React.ReactNode;
    label?: any;
    description?: string;
    value: any;
}

export interface selectorItem {
    label?: string,
    description?: string,
    value: string,
    key: string
    prefixElement?: React.ReactNode;
}
export const Colors = {
    red: 'red',
    orange: 'orange',
    yellow: 'yellow',
    green: 'green',
    default: 'default',
    auto: 'auto',
    lightYellow: 'lightYellow',
    grey:'grey',
    darkGrey: 'darkGrey',
    purple: 'purple',
    blue: 'blue',
    pink: 'pink',
    graphite: 'graphite'

} as const;

export type ColorKey = keyof typeof Colors;
