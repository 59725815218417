import {Icon, Avatar} from "ch3-ui-lib";
import {useState} from "react";
import {ColorKey, Colors} from "../../utilis/types/Types";

export const  colorItems = [
    {
        key: '1',
        value: Colors.orange,
    },
    {
        key: '2',
        value: Colors.green,
    },
    {
        key: '3',
        value: Colors.purple,
    },
    {
        key: '4',
        value: Colors.blue,
    },
    {
        key: '5',
        value: Colors.pink,
    },
    {
        key: '6',
        value: Colors.graphite,
    }
]

export const material_icons = ['home' , 'account_circle' , 'shopping_cart' , 'favorite' , 'lock' , 'thumb_up' , 'lightbulb' , 'star' , 'account_balance_wallet' , 'store' , 'work' , 'savings' , 'pets' , 'bookmark' , 'leaderboard' , 'pan_tool' , 'rocket_launch' , 'perm_media' , 'shopping_bag' , 'contact_support' , 'notifications' , 'school' , 'cookie' , 'sports_football' , 'weekend' , 'cloudy', 'email' , 'forum' , 'chat _bubble' , 'place' , 'local_library' , 'park' , 'local_cafe' , 'hotel' , 'navigation' , 'diamond' , 'terrain' , 'wine_bar' , 'icecream' , 'play_arrow' , 'videocam' , 'movie' , 'spa' , 'room_service'];

interface ManageIconProps {
    currentIcon: string;

    currentColor: any;
    // selectedIcon: any;

    setIcon: Function;
    setColor: Function;
}

const ManageIconModal = ({currentIcon, currentColor, setIcon, setColor}: ManageIconProps) => {
    const [icon, setNumberIcon] = useState(currentIcon);
    const [color, setNumberColor] = useState(currentColor);


    const handleIconChange = (icon: any) => {
        setNumberIcon(icon);
        setIcon(icon);
    }

    const handleColorChange = (color: ColorKey) => {
        setNumberColor(color);
        setColor(color);

    }

    return (
        <div className="w-[350px] mt-6">
            <div className='flex justify-between  items-end mb-10'>
                <div className={'text-center'}>

                    <div className={' flex justify-center'}>
                        <Avatar icon={icon ? icon : currentIcon} size="xxl" type='icon'
                                color={color ? color : currentColor}
                                name='John Doe'/>
                        <div className="flex flex-col items-start ml-6">
                            <div className="uppercase text-xs font-semibold ml-2">Color</div>
                            <div className="flex">
                                {
                                    colorItems.map((item, index) => (
                                        <div key={index} onClick={() => handleColorChange(item.value)}
                                             className={`bg-[${color}]  flex justify-center items-center w-6 h-6  m-2 `}>
                                            <Avatar className='ml-2 cursor-pointer' size="xs" type='icon' color={item.value} icon=''/>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className='flex flex-wrap w-full mt-5 mb-8 border border-black rounded p-4'>
                <p className="text-lg font-semibold mb-6">Icons</p>
                <div className='flex flex-wrap self-start'>

                    {
                        material_icons.map((icon, index) => (
                            <button key={index} onClick={() => handleIconChange(icon)}
                                    className='flex items-center justify-center cursor-pointer w-8 h-8 hover:bg-grey-50'>
                                <Icon iconName={icon}/>
                            </button>
                        ))
                    }
                </div>

            </div>
        </div>
    )
};

export default ManageIconModal;
