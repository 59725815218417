import {Avatar, Button, ChColumn, ChDropdown, ChInput, Icon, ChTooltip, Loader} from "ch3-ui-lib";
import { useIntl} from "react-intl";
import {useCallback, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {ChDropdownGroup, Colors,} from "../../utilis/types/Types";
import {useSelector} from "react-redux";
import noGroupsIcon from "../../assets/empty.svg";
import listEmpty from "../../assets/list-empty.svg";
import {FlagImage} from "react-international-phone";
import {getFlag} from "../../utilis/msisdnUtilis";
import {debounce} from 'lodash';
import {ClickToCall} from "../../components/ClickToCall/ClickToCall";
import useMediaQuery from "../../utilis/screenWidthUtils.ts";
import {useFetchUsersQuery, User} from "../../store/features/Users/userService";
import {Group, useFetchGroupsQuery} from "../../store/features/groups/groupsService";
import {buildQueryParams} from "../../utilis/buildQueryParams";
import {
    ContactListItem, ContactSelectItem,
    useGetContactsQuery
} from "../../store/features/Contacts/contactsService";
import { borderStyles, boxStyles} from "./helpers.tsx";
import { clsxMerge } from "../../utilis/classNameUtils.ts";
import {selectUserId} from "../../store/features/Auth/authSlice";


export default function ContactsList() {
    const [contacts, setContacts] = useState<ContactSelectItem[]>([]);
    const [totalCount, setTotalCount] = useState(0);

    const [userGroupPhrase, setUserGroupPhrase] = useState<string>('');
    const [phrase, setPhrase] = useState('');
    const [isSearching, setIsSearching] = useState(false);

    const userId = useSelector(selectUserId);
    const [usersAndGroupsFilter, setUsersAndGroupsFilter] = useState<any[]>([]);
    const [ sort, setSort] = useState<any>({});
    const [contactsQuery, setContactsQuery] = useState<any>({});


    const groupsFilter = usersAndGroupsFilter.filter((item) => item.hasOwnProperty('groupId'))
    const usersFilter = usersAndGroupsFilter.filter((item) => item.hasOwnProperty('userId'))

    const sharedForGroups = groupsFilter.map((item) => item.groupId);
    const sharedForUsers = usersFilter.map((item) => item.userId);

    const order = sort.order;
    const sortBy = sort.property;

    const userGroupQueryParams = buildQueryParams({
        phrase: userGroupPhrase,
        sharedForUsers, sharedForGroups, order , sortBy, userId

    });
    const contactsQueryParams = buildQueryParams(
        {
            ...contactsQuery,
            phrase,
            sharedForUsers,
            sharedForGroups,
            order,
            sortBy,
            userId
        }
    );


    const { data: usersData, isLoading: isLoadingUsers } = useFetchUsersQuery(userGroupQueryParams);
    const { data: groupsData, isLoading: isLoadingGroups } = useFetchGroupsQuery(userGroupQueryParams);
    const { data: contactsData, isLoading: isLoadingContacts, isFetching} = useGetContactsQuery(contactsQueryParams);


    useEffect(() => {
        if(contactsData?.totalCount !== totalCount) {
            setContacts([]);
        }
        if (contactsData) {
            setContacts((prevContacts: ContactSelectItem[]) => [...prevContacts, ...contactsData.contacts]);
            setTotalCount(contactsData.totalCount);
        }
        setIsSearching(false);
    }, [contactsData]);


    const loading = isLoadingUsers || isLoadingGroups || isLoadingContacts;
    const intl = useIntl();
    const name = intl.formatMessage({id: 'contacts.name'});
    const phoneNumber = intl.formatMessage({id: 'contacts.phone-number'});
    const sharedFor = intl.formatMessage({id: 'contacts.shared-for'});
    const handleDataChange = (order:string, property:string) => {
            setSort({order, property});
    };
    const isMobile = useMediaQuery('(max-width: 960px)');
    const theme = useSelector((state: any) => state.themeSwitcher.theme);


    const handleFilterSelect = (value: any) => {

        setContacts([]);
        setIsSearching(true)
        setContactsQuery({})

        const exists = usersAndGroupsFilter.some((item: any) => {
            const userIdMatches = item?.userId && value?.userId && item.userId === value.userId;
            const groupIdMatches = item?.groupId && value?.groupId && item.groupId === value.groupId;
            return userIdMatches || groupIdMatches;
        });

        const newUsersAndGroupsFilter = exists
            ? usersAndGroupsFilter.filter((item: any) => item.userId != value?.userId || item?.groupId != value?.groupId)
            : [...usersAndGroupsFilter, value];
        console.log(newUsersAndGroupsFilter, 'newUsersAndGroupsFilter');
        setUsersAndGroupsFilter(newUsersAndGroupsFilter);
    }



    const users = usersData?.length ? usersData?.map((user) => ({
        key: `user${user.userId}`,
        label: user.firstName + ' ' + user.lastName,
        value: user,
        prefixElement: <div className='mx-2'><Avatar name={user.firstName + ' ' + user.lastName} size="xs" type='initials' color={user.iconColor ?? 'orange'}/></div>,
    })) : [];


    const groups = groupsData?.groups?.length ? groupsData.groups?.map((group: any) => ({
        key:  `group${group.groupId}`,
        label: group.group.name,
        value: group.group,
        prefixElement: <div className='mx-2'><Avatar name={group.name} size="xs" type='icon' color={group.iconColor ?? 'orange'}
                                                     icon={group.icon ?? 'home'}/></div>,
        type: 'checkbox'
    })): [] ;

    const dropdownGroup: ChDropdownGroup[] = [{
        title: 'Users',
        items: users,
        callback: (value: string) => {
            handleFilterSelect(value);
        },
        type: 'checkbox'
    },
        {
            title: 'Groups',
            items: groups,
            type: 'checkbox',
            callback: (value: string) => {
                handleFilterSelect(value);
            }
        }];
    const filterButton = <Button size={"small"}  label='Shared for' leftIcon='filter_list'
                                 buttonType='textPrimary'/>



    const handlePhraseChange = useCallback(
        debounce((newPhrase: string) => {
            setContacts([]);
            setContactsQuery({})
            setPhrase(newPhrase);
            setIsSearching(true);
        }, 1000), // 1-second delay
        []
    );
    const fetchMoreContacts = () => {
        if (contacts.length > 0) {
            const lastContact = contacts[contacts.length - 1];
            setContactsQuery((prevParams: ContactSelectItem[]) => ({
                ...prevParams,
                lastContactId: lastContact.contactId,
                lastLastName: lastContact.lastName,
                lastFirstName: lastContact.firstName,
                lastMsisdn: lastContact.phoneNumber,
            }));
        }
    }


    return (
        <>
        {loading &&
            <div className={`flex w-full  ${isMobile ? ' px-4' : ' '}`}>
                <Loader/>
            </div>

        }
            {
            (    !loading && contactsData) &&
        <div className={`w-full ` }>

            <div className={`${isMobile ? ' px-4' : ''}`}>
            <div className={`flex justify-between items-center py-3` + `${isMobile? ' flex-wrap' : ' mx-5 no-wrap'} `}>
                    <ChDropdown trigger={filterButton} dropdownGroup={dropdownGroup}
                                onPhraseChange={(value: string) => setUserGroupPhrase(value)}
                                type='search'
                                selected={usersAndGroupsFilter}></ChDropdown>
                    <ChInput clearButton={true} placeholder={'Search'} className={`${isMobile? ' mb-[35px]' : 'min-w-[375px] h-[36px]'}`} value={phrase} prefixIcon={'search'}
                             callback={(value: string) => handlePhraseChange(value)}/>
                </div>

                <div className={`h-[calc(100vh-63px-var(--header-height))] overflow-y-auto border-t ` + clsxMerge(borderStyles({theme}), boxStyles({theme}))}>

                    {Boolean(contacts.length) &&
                        <table className={`w-full border-b ` + clsxMerge(borderStyles({theme}), boxStyles({theme}))} >

                        
                            <thead>
                            <tr className={`border-b ` + clsxMerge(borderStyles({theme}))}>
                                <th className={`pl-8 py-3 w-full ` + clsxMerge(borderStyles({theme}))}
                                ><ChColumn propertyName='FIRST_NAME' label={name} callback={handleDataChange}/></th>
                                <th className='min-w-[300px] '
                                ><ChColumn callback={handleDataChange} propertyName='MSISDN' label={phoneNumber} /></th>
                                <th className='min-w-[200px]'
                                ><ChColumn callback={handleDataChange} propertyName='sharedFor' label={sharedFor}/></th>
                            </tr>
                            </thead>
                            <tbody className={`border-b ` + clsxMerge(borderStyles({theme}))}>
                            {contacts.map((contact: ContactListItem, index: number) => {

                                    return (
                                        <tr className={`border-t border-grey-50 hover:bg-grey-50` } key={index}>
                                            <Link to={`/contacts/details/${contact.contactId}`}
                                                  className='no-underline block w-full '>

                                                <td className='flex gap-3  items-center pl-8 py-4 font-semibold text-sm'>
                                                    <Avatar className="border border-white rounded" size="md"
                                                                                                           type='initials'
                                                                                                           color='auto'
                                                                                                           name={contact.firstName + ' ' + contact.lastName}/>{contact.firstName + ' ' + contact.lastName}
                                                </td>
                                            </Link>

                                            <td>
                                                {contact?.phoneNumber && <div
                                                    className=" flex items-center gap-2 text-center text-xs font-bold leading-none">
                                                    <FlagImage
                                                        iso2={getFlag(contact.phoneNumber) ?? 'us'}
                                                        className='w-4 h-3'/>    <ClickToCall className="text-sm font-normal" noPrefix={true} msisdn={contact.phoneNumber}  />
                                                </div>}
                                            </td>

                                            <td>
                                                {contact.availability === "SHARED" && <div className=" justify-start items-center flex">
                                                    {
                                                        [...(contact.sharedForUsers ?? []), ...(contact.sharedForGroups ?? [])]
                                                            .slice(0, 6)
                                                            .map((item, index) => {
                                                                const isUser = (item as User).firstName !== undefined; // Type guard to check if item is User
                                                                return (
                                                                    <ChTooltip
                                                                        alignArrow='start'
                                                                        trigger={<div
                                                                        key={index}

                                                                        style={{right: `${index * 20}px`}}
                                                                        className={`p-[2px] bg-white relative`}
                                                                    >
                                                                        <Avatar
                                                                            size="md"
                                                                            type={isUser ? 'initials' : ((item as Group).groupId ? 'initials' : 'icon')}
                                                                            name={isUser ? `${(item as User).firstName} ${(item as User).lastName}` : (item as Group).name}
                                                                            icon={isUser ? undefined : 'home'}

                                                                            color={isUser ? item.iconColor : Colors.darkGrey}
                                                                        />

                                                                    </div>

                                                                    } content={
                                                                            isUser ? `${(item as User).firstName} ${(item as User).lastName}` : (item as Group).name
                                                                  }
                                                                               theme={'dark'}

                                                                  />

                                                                );
                                                            })
                                                    }
                                                    {
                                                        [...(contact.sharedForUsers ?? []), ...(contact.sharedForGroups ?? [])].length > 6 &&
                                                        <div
                                                            key={index}
                                                            style={{right: `120px`}}
                                                            className={`p-[2px] bg-white relative right-${index}`}
                                                        >
                                                            <Avatar
                                                                color={Colors.grey}

                                                                size="md"
                                                                type='initials'
                                                                name={`+ ${[...(contact.sharedForUsers ?? []), ...(contact.sharedForGroups ?? [])].length - 6}`}

                                                            />
                                                        </div>
                                                    }

                                                </div>}
                                                {contact.availability === "PRIVATE" && <div className="gap-3 justify-start items-center flex font-medium">
                                                    <Icon
                                                        size="md"
                                                        iconName='lock'

                                                    />
                                                    <div>Private</div>
                                                    </div>}
                                                {contact.availability === "PUBLIC" && <div className="gap-3 justify-start items-center flex font-medium">
                                                    <Icon
                                                        size="md"

                                                        iconName='public'

                                                    />
                                                    <div>Public</div>
                                                </div>}
                                            </td>
                                        </tr>
                                    )
                                }
                            )}
                            </tbody>
                        </table>}
                    {contacts.length === 0 && (!isFetching && isSearching ) && !loading && ( phrase.length === 0 || usersAndGroupsFilter.length === 0) &&
                        <div className={`flex text-center  justify-center  border-b ` + clsxMerge(borderStyles({theme}))}>
                            <div className='w-[450px]  m-14'>
                                <div className='flex  justify-center w-full'>
                                    <img className='m-4' src={noGroupsIcon}/>
                                </div>

                                <h1 className='font-bold'>
                                    No contacs found
                                </h1>
                                <div className='mb-6 mt-2'>
                                    No contacts found matching your search. Please double-check your criteria or
                                    consider adding new groups to expand your network!
                                </div>
                                <div className='flex justify-center'>
                                    <Link to='/contacts/new'>
                                        <Button shadow leftIcon='add' buttonType='primary' label='Create new contact'/>
                                    </Link>

                                </div>
                            </div>

                        </div>

                    }

                    {isFetching &&
                        <Loader/>
                    }

                    {(!totalCount && (!isFetching && !isSearching ) && (!phrase.length && !usersAndGroupsFilter.length)) &&
                        <div className={`flex text-center  justify-center border-b border-black`}>
                            <div className='w-[450px]  m-14'>
                                <div className='flex  justify-center w-full'>
                                    <img className='m-4' src={listEmpty}/>
                                </div>

                                <h1 className='font-bold'>
                                    No contacts yet!
                                </h1>
                                <div className='mb-6 mt-2'>
                                    Click below to add one now.
                                </div>
                                <div className='flex justify-center'>
                                    <Link to='/contacts/new'>
                                        <Button shadow leftIcon='add' buttonType='primary' label='Create new contact'/>
                                    </Link>
                                </div>
                            </div>

                        </div>

                    }
                    {
                        (Boolean(contacts.length) && contacts.length != totalCount)  &&
                        <div className='flex justify-center my-4'>
                            <Button disabled={isFetching} onClick={fetchMoreContacts} leftIcon={'more_horiz'} size='small' buttonType='textSecondary'
                                    label='Load more'/>
                        </div>
                    }
                </div>

            </div>
        </div>
}
</>
    )
}
