import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from "../../store";
import {CallStateEnum} from "../../../components/Dialer/Dialer";

interface DialerState {
    isDialerHidden: boolean;
    isInCalls: boolean;
    selectedPhoneNumber: any | undefined;
    selectedMicrophone: string | null;
    selectedSpeaker: string ;
    autoGainControl: boolean;
    incomingCall: boolean;
    callFrom: string ;
    callState: CallStateEnum;
    phoneNumber: string;
    selectedPrefix: {
        isoCountry: string;
        prefix: string | null;
    };
    showKeypad: boolean;
    sipInstanceId: string | null;
}

const initialState: DialerState = {
    isDialerHidden: true,
    isInCalls: false,
    selectedPhoneNumber: undefined,
    selectedMicrophone: null,
    selectedSpeaker: '',
    incomingCall: false,
    autoGainControl: false,
    callFrom: '',
    callState: CallStateEnum.IDLE,
    phoneNumber: '',
    selectedPrefix: {
        isoCountry: 'US',
        prefix: '+1',
    },
    showKeypad: false,
    sipInstanceId: null,
};


const dialerSlice = createSlice({
    name: 'dialer',
    initialState,
    reducers: {
        setSelectedPhoneNumber(state, action: PayloadAction<any>) {
            state.selectedPhoneNumber = action.payload;
        },
        toggleDialerHidden: (state) => {
            state.isDialerHidden = !state.isDialerHidden;
        },
        setIsInCallsAction: (state, action) => {
            state.isInCalls = action.payload;
            state.isDialerHidden = false;
        },
        setSelectedMicrophoneDevice: (state, action) => {
            state.selectedMicrophone = action.payload;
        },
        setSelectedSpeakerDevice: (state, action) => {
            state.selectedSpeaker = action.payload;
        },
        setIncomingCall: (state, action) => {
            state.incomingCall = action.payload;
        },
        setAutoGainControl: (state, action) => {
            state.autoGainControl = action.payload;
        },
        setCallFrom: (state, action) => {
            state.callFrom = action.payload;
            state.showKeypad = false;
        },
        setCallState: (state, action) => {
            state.callState = action.payload;
        },
        setPhoneNumber: (state, action) => {
            state.phoneNumber = action.payload;
            state.isDialerHidden = false;

        },
        setSelectedPrefix: (state, action) => {
            console.log('setSelectedPrefix action.payload', action.payload);
            state.selectedPrefix =  {
                isoCountry: action.payload.isoCountry,
                prefix:action.payload.prefix,
            };
        },
        setShowKeypad: (state, action) => {
            state.showKeypad = action.payload;
        },
        setSipInstanceId: (state, action: PayloadAction<{ sipInstanceId: string }>) => {
            state.sipInstanceId = action.payload.sipInstanceId;
        },
    },
});
export const {setSelectedPhoneNumber} = dialerSlice.actions;

export const { toggleDialerHidden, setIsInCallsAction, setSelectedMicrophoneDevice, setSelectedSpeakerDevice, setIncomingCall, setAutoGainControl, setCallFrom, setCallState, setPhoneNumber, setSelectedPrefix, setShowKeypad, setSipInstanceId } = dialerSlice.actions;

export const selectSelectedPhoneNumber = (state: RootState) =>
    state.dialer.selectedPhoneNumber;
export const getSelectedMicrophone = (state: RootState) =>
    state.dialer.selectedMicrophone;
export const getSelectedSpeaker = (state: RootState) =>
    state.dialer.selectedSpeaker;
export const getAutoGainControl = (state: RootState) =>
    state.dialer.autoGainControl;
export default dialerSlice.reducer;
export const getIsDialerHidden = (state: RootState) => state.dialer.isDialerHidden;
export const selectSelectedPrefix = (state: RootState) => state.dialer.selectedPrefix;
export const getShowKeypad = (state: RootState) => state.dialer.showKeypad;
