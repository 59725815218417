import { createSlice } from '@reduxjs/toolkit';
import moment from "moment";

export const initialState = {
    phoneNumbersFilter: [],
    contactsFilter: [],
    usersAndGroupsFilter: [],
        selectedDates: { from: moment().clone().subtract(100, 'years').startOf('day').toISOString(), to: moment().clone().add(100, 'years').endOf('day').toISOString() },
    limit: 20,
};

const filtersSlice = createSlice({
    name: 'filters',
    initialState,
    reducers: {
        setPhoneNumbersFilter: (state, action) => {
            state.phoneNumbersFilter = action.payload;
        },
        setContactsFilter: (state, action) => {
            state.contactsFilter = action.payload;
        },
        setUsersAndGroupsFilter: (state, action) => {
            state.usersAndGroupsFilter = action.payload;
        },
        setSelectedDates: (state, action) => {
            state.selectedDates = action.payload;
        },
        setLimit: (state, action) => {
            state.limit = action.payload;
        },
    },
});

export const {
    setPhoneNumbersFilter,
    setContactsFilter,
    setUsersAndGroupsFilter,
    setSelectedDates,
    setLimit,
} = filtersSlice.actions;

export default filtersSlice.reducer;
