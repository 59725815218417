import {Button, ChModal, ChRadio, Icon, Loader, Switch} from "ch3-ui-lib";
import {useEffect, useRef, useState} from "react";
import ForwardCall from "./Modal/ForwardCall";
import {isValidPhoneNumber, parsePhoneNumber} from "libphonenumber-js";
import {Link, useNavigate, useParams} from "react-router-dom";
import {FlagImage} from "react-international-phone";
import {getFlag} from "../../utilis/msisdnUtilis";
import {useSelector} from "react-redux";
import WelcomeMessage from "./Modal/WelcomeMessage";
import AudioPlayer from "../../components/AudioPlayer/AudioPlayer";
import useMediaQuery from "../../utilis/screenWidthUtils.ts";
import {
    AudioFilesURL, CallRulesDTO,
    useFetchCallRulesQuery,
    useGetRecordingQuery, usePostRecordingPreviewMutation, useSynthetizeTextMutation,
    useUpdateCallRulesMutation, useUploadSynthetizeTextMutation,
} from "../../store/features/MsisdnSettings/msisdnSettingsService";

import {clsxMerge} from "../../utilis/classNameUtils.ts";
import {borderStyles, boxStyles} from "./helpers.tsx";
import {CallQueueModal} from "./Modal/CallQueueModal";
import {useGetQueueByIdQuery} from "../../store/features/CallQueue/CallQueueService";

interface CallRulesProps {
    admin?: boolean;
}
const CallRules = ({admin}:CallRulesProps ) => {

    const theme = useSelector((state: any) => state.themeSwitcher.theme);
    const {id} = useParams<{ id: string }>() as { id: string };
    const navigate = useNavigate();
    const isMobile = useMediaQuery('(max-width: 960px)');
    const [isValidMsisdn, setIsValidMsisdn] = useState<boolean>(false);
    const [forwardMsisdnWorkingDraft, setForwardMsisdnWorkingDraft] = useState<string>('');
    const [forwardMsisdnNotWorkingDraft, setForwardMsisdnNotWorkingDraft] = useState<string>('');

    const [queueDraft, setQueueDraft] = useState<number | null>(null);

    const [pendingUpload, setPendingUpload] = useState<string>('');

    const [postRecordingPreview, {data: tempFile}] = usePostRecordingPreviewMutation();

    const [synthetizeText, { data: tempTtsFile }] = useSynthetizeTextMutation();

    const [postSynthTextPreview, {data: synthText}] = useUploadSynthetizeTextMutation();

    const {data: callRules, isLoading} = useFetchCallRulesQuery(id);
    const {data: afterHoursAudioUrl, isLoading: isLoadingAfterHorus} = useGetRecordingQuery({id, type: 'AFTER_HOURS_MESSAGE'});
    const {data: workingHoursAudioUrl,  isLoading: isLoadingWelcome} = useGetRecordingQuery({id, type: 'WORKING_HOURS_WELCOME_MESSAGE'});


    const [updateCallRules] = useUpdateCallRulesMutation();

    const initialState: CallRulesDTO = {
        msisdnId: undefined,
        workingForwardEnabled: false,
        workingForwardMsisdn: null,
        workingWelcomeMessageEnabled: false,
        nonWorkingForwardEnabled: false,
        nonWorkingForwardMsisdn: null,
        nonWorkingDontAcceptCalls: false,
        nonWorkingAfterHoursMessageEnabled: false,
        nonWorkingAfterHoursMessageOriginalName: null,
        nonWorkingAfterHoursMessageBucketName: null,
        nonWorkingWelcomeMessageBucketName: null,
        workingWelcomeMessageOriginalName: null,
        workingWelcomeMessageBucketName: null,
        queueEnabled: false,
        queueId: null,
    };

    const [formData, setFormData] = useState(initialState);

    const {data: selectedQueue} = useGetQueueByIdQuery(Number(formData?.queueId), {skip: !formData?.queueId});

    useEffect(() => {
        if (callRules) {
            setFormData(callRules as CallRulesDTO);
        }
    }, [callRules]);

    useEffect(() => {

        if (pendingUpload == 'welcomeMessageWorking' && tempFile) {
            setAudioURL((prevState) => ({
                ...prevState,
                workingWelcomeMessageUrl: tempFile.audioURL
            }));
            setFormData(prevState =>
                ({
                    ...prevState,
                    workingWelcomeMessageEnabled: true,
                    workingWelcomeMessageOriginalName: tempFile.originalFileName,
                    workingWelcomeMessageBucketName: tempFile.bucketFileName,
                }));

            setPendingUpload('');
        }
        if (pendingUpload == 'afterHoursMessage' && tempFile) {
            setAudioURL((prevState) => ({
                ...prevState,
                nonWorkingWelcomeMessageUrl: tempFile.audioURL
            }));
            setFormData(prevState =>
                ({
                    ...prevState,
                    nonWorkingAfterHoursMessageEnabled: true,
                    nonWorkingAfterHoursMessageOriginalName: tempFile.originalFileName,
                    nonWorkingAfterHoursMessageBucketName: tempFile.bucketFileName,
                }));
            setPendingUpload('');
        }
    }, [tempFile, pendingUpload]);

    useEffect(() => {

        if (pendingUpload == 'welcomeMessageWorking' && synthText) {
            setAudioURL((prevState) => ({
                ...prevState,
                workingWelcomeMessageUrl: tempTtsFile.data
            }));
            setFormData(prevState =>
                ({
                    ...prevState,
                    workingWelcomeMessageEnabled: true,
                    workingWelcomeMessageOriginalName: synthText.originalFileName,
                    workingWelcomeMessageBucketName: synthText.bucketFileName,
                    workingForwardEnabled: false,
                }));

            setPendingUpload('');
        }
        if (pendingUpload == 'afterHoursMessage' && synthText) {
            setAudioURL((prevState) => ({
                ...prevState,
                nonWorkingWelcomeMessageUrl: tempTtsFile.data
            }));
            setFormData(prevState =>
                ({
                    ...prevState,
                    nonWorkingAfterHoursMessageEnabled: true,
                    nonWorkingAfterHoursMessageOriginalName: synthText.originalFileName,
                    nonWorkingAfterHoursMessageBucketName: synthText.bucketFileName,
                    nonWorkingForwardEnabled: false,
                    nonWorkingDontAcceptCalls: false
                }));
            setPendingUpload('');
        }
    }, [synthText, pendingUpload]);

    const [audioURL, setAudioURL] = useState<AudioFilesURL>({
        workingWelcomeMessageUrl: workingHoursAudioUrl?.data,
        nonWorkingWelcomeMessageUrl: afterHoursAudioUrl?.data,
    });
    useEffect(() => {

        setAudioURL({
            workingWelcomeMessageUrl: workingHoursAudioUrl?.data,
            nonWorkingWelcomeMessageUrl: afterHoursAudioUrl?.data,
        });
    }, [afterHoursAudioUrl, workingHoursAudioUrl]);


    const welcomeMessageWorking = {
        label: 'Welcome message',
        description: 'Create a friendly greeting for callers to enhance their experience.',
        url: audioURL.workingWelcomeMessageUrl,
        isEnabled: formData?.workingWelcomeMessageEnabled,
    }


    const callOptions = {
        afterHoursMessage: {
            label: 'After hours message',
            description: 'Create a friendly greeting for callers to enhance their experience.',
            url: audioURL.nonWorkingWelcomeMessageUrl,
            isEnabled: formData?.nonWorkingAfterHoursMessageEnabled,
        },
        forwardWorking: {
            label: 'Forward call',
            description: 'Calls will be forwarded to the specified phone number.',
            url: audioURL.workingWelcomeMessageUrl,
            isEnabled: formData?.workingForwardEnabled,
        },
        forwardNonWorking: {
            label: 'Forward call',
            description: 'Calls will be forwarded to the specified phone number.',
            url: audioURL.workingWelcomeMessageUrl,
            isEnabled: formData?.nonWorkingForwardEnabled,
        },
        receiveIncomingCall: {
            label: 'Receive incoming calls',
            description: 'You will answer the incoming call.',
        },
        dontAcceptCals: {
            label: 'Don’t accept calls',
            description: 'Callers receive a busy signal.',
        },
        callQueue: {
            label: 'Call queue',
            description: 'The call will be routed to the appropriate queue based on predefined criteria.',
        }

    }


    const welcomeMessageWorkingRef = useRef<{ onSubmit: () => void, openModal: () => void }>(null);
    const afterHoursMessageRef = useRef<{ onSubmit: () => void, openModal: () => void }>(null);

    const forwardCallWorkingRef = useRef<{ onSubmit: () => void, openModal: () => void }>(null);
    const forwardCallNonWorkingRef = useRef<{ onSubmit: () => void, openModal: () => void }>(null);

    const callQueueRef = useRef<{ onSubmit: () => void, openModal: () => void }>(null);

    const isAudioLoading = (type: string) => {
        if (type === 'welcomeMessageWorking') {
            return isLoadingWelcome;
        }
        if (type === 'afterHoursMessage') {
            return isLoadingAfterHorus;
        }
    }

    const getCallOption = (label: string, description: string, isEnabled: boolean, type: string, ref: any, element: any, callback?: () => void, url?: string | undefined) => {
        const item = {
            id: type,
            value: type,
            label: label,
            description: description,
        }
        return (
            <div
                id={type}
                className={`p-4 border border-dotted mt-2 hover:border-primary-500 hover:border-solid cursor-pointer` + clsxMerge(borderStyles({theme}))}>
                <div className='flex justify-between items-start'>
                    { (type === 'welcomeMessageWorking' || type === 'afterHoursMessage') &&
                        <>
                            {!isAudioLoading(type) && <div className="flex ">
                                <div className="max-w-[100px]">

                                    <Switch size='lg' checked={isEnabled}
                                            callback={() => {
                                                if (!isEnabled && ref && !url) {
                                                    ref.current?.openModal()
                                                } else if (callback) {
                                                    callback()
                                                }

                                            }}
                                            alignLeft={true}></Switch>
                                </div>
                                <div className="flex flex-col">
                                    <div className='font-semibold'>{label}</div>
                                    <div
                                        className='text-grey-500 text-sm items-center'>{description}</div>
                                </div>
                            </div>}
                            {isAudioLoading(type) &&

                                <div className={' items-start'}>
                                    <Loader className={'!h-[74px] w-full'} />
                                </div>
                            }
                        </>
                    }
                    {!(type === 'welcomeMessageWorking' || type === 'afterHoursMessage') &&
                        <ChRadio
                            singleRadio={true}
                            radioItem={item}
                            alignLeft={false}
                            callback={() => {
                                if (!isEnabled && ref && !url) {
                                    ref.current?.openModal()
                                }
                                if (callback) {
                                    callback()
                                }

                            }}
                            value={isEnabled ? type : false}
                        />
                    }

                    {isEnabled && element && <Button size='small' buttonType='textSecondary'
                                                     onClick={(e) => {
                                                         ref.current?.openModal()
                                                         e.stopPropagation()
                                                     }
                                                     } label='Change'></Button>}
                </div>
                {isEnabled && element}
            </div>

        )
    }

    const getAudioPlayer = (url: string | undefined, isEnabled: boolean) => {
        return (
            url &&
            <div className='mt-4' onClick={event => event.stopPropagation()}>
                <AudioPlayer disabled={!isEnabled} src={url}/>
            </div>

        )
    }

    const getForwardNumber = (phoneNumber: number | null) => {
        return (
            phoneNumber &&
            <div className='mt-4 text-sm font-medium'>
                Phone number:
                <div className='flex bg-grey-50 text-gray-400 p-3 items-center gap-2 text-sm'>
                    <FlagImage className='w-4 h-4'
                               iso2={getFlag(phoneNumber) ?? 'us'}/>
                    <div>{parsePhoneNumber(`+${phoneNumber}`).formatNational()}</div>
                </div>
            </div>);
    }
    const nonWorkingDontAcceptCallsChange = () => {

        setFormData(prevState =>
            ({
                ...prevState,
                nonWorkingDontAcceptCalls: true,
                nonWorkingForwardEnabled: false
            }));


    }
    const receiveIncomingCallChange = () => {
        setFormData(prevState =>
            ({
                ...prevState,
                workingForwardEnabled: false,
                queueEnabled: false
            }));
    }
    const enableWelcomeMessage = () => {

        setFormData(prevState =>
            ({
                ...prevState,
                workingWelcomeMessageEnabled: !prevState.workingWelcomeMessageEnabled ,
            }));
    }
    const enableAfterHoursMessage = () => {

        setFormData(prevState =>
            ({
                ...prevState,
                nonWorkingAfterHoursMessageEnabled: !prevState.nonWorkingAfterHoursMessageEnabled,

            }));
    }
    if (isLoading) {
        return (
            <div
                className={`flex w-full  ${isMobile ? ' px-4' : ''}`}>
                <Loader/>
            </div>
        )
    }


    const welcomeMessageAudioPlayer = getAudioPlayer(audioURL.workingWelcomeMessageUrl, formData.workingWelcomeMessageEnabled);
    const welcomeMessageTrigger = getCallOption(welcomeMessageWorking.label, welcomeMessageWorking.description, welcomeMessageWorking.isEnabled, 'welcomeMessageWorking', welcomeMessageWorkingRef, welcomeMessageAudioPlayer, enableWelcomeMessage, welcomeMessageWorking.url);


    const forwardCallNumberElement = getForwardNumber(formData.workingForwardMsisdn);
    const forwardCallWorkingTrigger = getCallOption(callOptions.forwardWorking.label, callOptions.forwardWorking.description, callOptions.forwardWorking.isEnabled, 'forwardWorking', forwardCallWorkingRef, forwardCallNumberElement)

    const forwardCallNonWorkingElement = getForwardNumber(formData.nonWorkingForwardMsisdn);
    const forwardCallNonWorkingTrigger = getCallOption(callOptions.forwardNonWorking.label, callOptions.forwardNonWorking.description, callOptions.forwardNonWorking.isEnabled, 'forwardNonWorking', forwardCallNonWorkingRef, forwardCallNonWorkingElement)

    const isReceiveIncomingCallEnabled = !formData.workingForwardEnabled && !formData.queueEnabled;
    const receiveIncomingCallTrigger = getCallOption(callOptions.receiveIncomingCall.label, callOptions.receiveIncomingCall.description, isReceiveIncomingCallEnabled, 'receiveIncomingCall', undefined, undefined, receiveIncomingCallChange)

    const afterHoursMessageAudioPlayer = getAudioPlayer(audioURL.nonWorkingWelcomeMessageUrl, formData.nonWorkingAfterHoursMessageEnabled);
    const afterHoursMessageTrigger = getCallOption(callOptions.afterHoursMessage.label, callOptions.afterHoursMessage.description, callOptions.afterHoursMessage.isEnabled, 'afterHoursMessage', afterHoursMessageRef, afterHoursMessageAudioPlayer, enableAfterHoursMessage, callOptions.afterHoursMessage.url);

    const dontAcceptCallsElement = getCallOption(callOptions.dontAcceptCals.label, callOptions.dontAcceptCals.description, formData.nonWorkingDontAcceptCalls, 'dontAcceptCalls', undefined, undefined, nonWorkingDontAcceptCallsChange);

    const callQueueElement = <div className='mt-4 text-sm font-medium'>
        <div className='flex bg-grey-50 text-gray-400 p-3 items-center gap-2 text-sm'>
            <Icon iconName='library_add'></Icon>
            <div>{selectedQueue?.name}</div>
        </div>
    </div>
    const callQueueTrigger = getCallOption(callOptions.callQueue.label, callOptions.callQueue.description, formData.queueEnabled, 'callQueue', callQueueRef, callQueueElement);


    const checkIfIsValidPhoneNumber = (val: string, type: string) => {
        const phoneNumber = `+${val}`;
        if (phoneNumber.length == 0) {
            setIsValidMsisdn(true);
            setForwardMsisdnWorkingDraft('');
            return true;
        }
        if (type == 'working') {

            try {
                const isValid = isValidPhoneNumber(phoneNumber);
                setIsValidMsisdn(isValid);
                setForwardMsisdnWorkingDraft(val);
                return isValid;
            } catch (error) {
                setIsValidMsisdn(false);
                setForwardMsisdnWorkingDraft('');
                return false;
            }
        } else {
            try {
                const isValid = isValidPhoneNumber(phoneNumber);
                setIsValidMsisdn(isValid);
                setForwardMsisdnNotWorkingDraft(val);
                return isValid;
            } catch (error) {
                setIsValidMsisdn(false);
                setForwardMsisdnNotWorkingDraft('');
                return false;
            }
        }

    }
    const getContent = (type: string) => {
        return <ForwardCall
            msisdn={type == 'working' ? formData.workingForwardMsisdn : formData.nonWorkingForwardMsisdn} type={type}
            isValid={checkIfIsValidPhoneNumber}></ForwardCall>
    }

    const handleWorkingHoursForwardCall = (res: boolean) => {
        if (res) {
            if (forwardMsisdnWorkingDraft.length == 0) {
                setFormData(prevState =>
                    ({
                        ...prevState,
                        workingForwardEnabled: false,
                        workingForwardMsisdn: null
                    }));
                return;
            }
            setFormData(prevState =>
                ({
                    ...prevState,
                    workingForwardEnabled: true,
                    queueEnabled: false,
                    workingForwardMsisdn: Number(forwardMsisdnWorkingDraft),
                }));
        }
    }
    const handleNotWorkingHoursForwardCall = (res: boolean) => {
        if (res) {
            if (forwardMsisdnNotWorkingDraft.length == 0) {
                setFormData(prevState =>
                    ({
                        ...prevState,
                        nonWorkingForwardEnabled: false,
                        nonWorkingForwardMsisdn: null

                    }));
                return;
            }
            setFormData(prevState =>
                ({
                    ...prevState,
                    nonWorkingForwardEnabled: true,
                    nonWorkingForwardMsisdn: Number(forwardMsisdnNotWorkingDraft),
                    nonWorkingDontAcceptCalls: false
                }));
        }
    }


    const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        const data = {
            ...formData,
            workingForwardMsisdn: formData.workingForwardMsisdn ? Number(formData.workingForwardMsisdn) : null,
            nonWorkingForwardMsisdn: formData.nonWorkingForwardMsisdn ? Number(formData.nonWorkingForwardMsisdn) : null,
        }
        if (!id) return;
        await updateCallRules({id, data});
        navigate((admin ? '/admin' : '' ) +  '/phoneNumbers');
    };

    const handleWelcomeMessageWorkingSubmit = (res: boolean) => {
        if (!res) return;
        setPendingUpload('welcomeMessageWorking');

    }

    const handleAfterHoursMessageSubmit = (res: boolean) => {
        if (!res) return;
        setPendingUpload('afterHoursMessage');
    }
    const handleCallQueueSubmit = (res: boolean) => {
        if (!res) return;
        setFormData(prevState =>
            ({
                ...prevState,
                queueEnabled: true,
                queueId: queueDraft,
                workingForwardEnabled: false,
            }));
    }

    return (
        <>
            {formData &&
                <div className='w-full'>
                    <div>
                        <div
                            className={` overflow-x-hidden  overflow-y-auto ${isMobile ? ' px-4' : ''}`}>
                            <div
                                className={`flex flex-col   ${isMobile ? ' my-6 ' : ''} ` + clsxMerge(borderStyles({theme}), boxStyles({theme}))}>
                                <div>

                                    <div className='font-semibold px-6 pt-6 text-lg'>
                                        Call rules
                                    </div>
                                    <div className='text-grey-500 px-6 pb-6 text-sm'>
                                        The incoming call will be displayed to users assigned to the number.
                                    </div>
                                </div>
                                <div className='flex justify-center'>
                                    <div
                                        className={`border  flex items-center border-dotted justify-center w-[200px] py-4 ` + clsxMerge(borderStyles({theme}))}>
                                        <Icon iconName='call_received'></Icon> Incoming calls
                                    </div>
                                </div>
                                <div className='flex  text-sm'>
                                    <div className='flex-col m-6 justify-center w-1/2'>
                                        <div>
                                            <div className='text-center mb-6 font-semibold'>
                                                What happens when you work?
                                            </div>
                                            <div>{welcomeMessageTrigger}</div>
                                            <div className={'hidden'}>
                                                <ChModal
                                                    closeButton={true}
                                                    ref={welcomeMessageWorkingRef}
                                                    callback={handleWelcomeMessageWorkingSubmit}
                                                    title={'Create a welcome message'}
                                                    content={<WelcomeMessage uploadFile={postRecordingPreview}
                                                                             synthetizeText={synthetizeText}
                                                                             uploadSynthText={postSynthTextPreview}
                                                                             welcomeMessageWorkingRef={welcomeMessageWorkingRef}
                                                    />
                                                    }
                                                />
                                            </div>

                                        </div>
                                        {forwardCallWorkingTrigger}
                                        {callQueueTrigger}
                                        {receiveIncomingCallTrigger}

                                        <ChModal callback={handleWorkingHoursForwardCall}
                                                 title={'Forward call'}
                                                 isValid={isValidMsisdn}
                                                 content={getContent('working')}
                                                 secondaryLabel='Cancel'
                                                 primaryLabel='Confirm'
                                                 ref={forwardCallWorkingRef}

                                        />
                                        <ChModal
                                            ref={callQueueRef}
                                            callback={handleCallQueueSubmit}
                                            title={'Queue'}
                                            secondaryLabel='Cancel'
                                            primaryLabel='Confirm'
                                            content={<CallQueueModal setQueue={setQueueDraft}/>}
                                        />
                                    </div>
                                    <div className='flex-col m-6 justify-center  w-1/2 '>
                                        <div className='text-center mb-6 font-semibold'>

                                            What happens when you don't work?
                                        </div>
                                        {afterHoursMessageTrigger}
                                        <ChModal
                                            closeButton={true}
                                            ref={afterHoursMessageRef}
                                            callback={handleAfterHoursMessageSubmit}
                                            title={'Create a after hours message'}
                                            content={<WelcomeMessage uploadFile={postRecordingPreview}
                                                                     synthetizeText={synthetizeText}
                                                                     uploadSynthText={postSynthTextPreview}
                                                                     welcomeMessageWorkingRef={afterHoursMessageRef}
                                            />
                                            }
                                        />
                                        {forwardCallNonWorkingTrigger}
                                        <ChModal callback={handleNotWorkingHoursForwardCall} title={'Forward call'}
                                                 isValid={isValidMsisdn}
                                                 content={getContent('noWorking')} secondaryLabel='Cancel'
                                                 primaryLabel='Confirm'
                                                 ref={forwardCallNonWorkingRef}

                                        />

                                        {dontAcceptCallsElement}


                                    </div>
                                </div>
                            </div>
                            <div className={` flex justify-end border-t border-black`}>
                                <div className='flex gap-3 justify-end m-8'>
                                    <Link to={'/phoneNumbers'}><Button buttonType='secondary' size='large'
                                                                       label={'Cancel'}></Button></Link>
                                    <Button size='large' onClick={(e) => handleSubmit(e)} label={'Done'}></Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}
export default CallRules;
