import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../../baseQuery';

export interface GoogleUser {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
}

export interface GoogleIntegrationResponse {
    googleResponse: {
        users: GoogleUser[];
    };
    integrationId: number;
}

export const integrationApi = createApi({
    reducerPath: 'integrationApi',
    baseQuery,
    endpoints: (builder) => ({
        startIntegrationGoogle: builder.mutation<GoogleIntegrationResponse, { email: string }>({
            query: ({ email }) => ({
                url: '/api/v1/integrations/google',
                method: 'POST',

                body: { email },
            }),
        }),

        importGoogleContacts: builder.mutation<any, { integration: number; users: GoogleUser[] }>({
            query: ({ integration, users }) => ({
                url: `/api/v1/integrations/google/${integration}/users`,
                method: 'POST',
                body:{ integration, users },
            }),
        }),
    }),
});

export const {
    useStartIntegrationGoogleMutation,
    useImportGoogleContactsMutation,
} = integrationApi;
