import {Avatar, Button, ChInput, ChModal, ChRadio, Icon} from "ch3-ui-lib";
import AddContactModal from "./AddContactModal";
import {useEffect, useRef, useState} from "react";
import 'react-international-phone/style.css';
import {useSearchParams, Link} from "react-router-dom";
import {Colors} from "../../utilis/types/Types";
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {useNavigate} from "react-router-dom";
import DeleteContactModal from "./DeleteContact";
import {useForm, Controller, useFieldArray} from "react-hook-form";
import useMediaQuery from "../../utilis/screenWidthUtils.ts";
import {isValidPhoneNumber} from "libphonenumber-js/max";
import {removeEmptyFields} from "../../utilis/removeEmptyFields";
import {validateEmail} from "../../utilis/commonValidators";
import {borderStyles, boxStyles} from "./helpers.tsx";
import {clsxMerge} from "../../utilis/classNameUtils.ts";
import {PhoneNumberInput} from "../../components/Dialer/components/PhoneInput";
import {
    useAddContactMutation,
    useGetContactByIdQuery,
    useUpdateContactMutation
} from "../../store/features/Contacts/contactsService";
import {Group} from "../../store/features/groups/groupsService";
import {User} from "../../store/features/Users/userService";
import {selectUserId} from "../../store/features/Auth/authSlice";


const RadioGroup = [{
    id: 'r1',
    label: 'Public',
    description: 'Is a contact that can be viewed and edited by everyone in your organization.',
    value: 'PUBLIC',
    callback: () => {
        console.log('Radio clicked!');
    }
}, {
    id: 'r2',
    label: 'Private',
    description: 'Is a contact that is visible only to you.',
    value: 'PRIVATE',
    callback: () => {
        console.log('Radio clicked!');
    }
},
    {
        id: 'r3',
        label: 'Shared',
        description: 'Is a contact that can be viewed and edited by assigned people.',
        value: 'SHARED',
        callback: () => {
            console.log('Radio clicked!');
        }
    }];

const AddEditContact = () => {
    const {id} = useParams<{ id: string }>();
    const [searchParams] = useSearchParams();
    const clientNumber = searchParams.get('phoneNumber');
    const isNewContact = !id;
    const theme = useSelector((state: any) => state.themeSwitcher.theme);


    const navigate = useNavigate();


    const {data: contactData} = useGetContactByIdQuery(Number(id), {skip: isNewContact});
    const isMobile = useMediaQuery('(max-width: 960px)');

    const [createContact] = useAddContactMutation();
    const [updateContact] = useUpdateContactMutation();

    const {
        control,
        handleSubmit,
        setValue,
        getValues,
        watch,
        setFocus,
        formState: {errors},
    } = useForm({
        mode: 'onBlur',
        defaultValues: {
            phoneNumbers: [
                {
                    msisdn: clientNumber ?? '',
                    main: true,
                },
            ],
            firstName: '',
            lastName: '',
            email: '',
            company: '',
            availability: 'PUBLIC',
            sharedForUsers: [] as User[],
            sharedForGroups: [] as Group[],
            createdAt: new Date().toISOString(),
            createdBy: useSelector(selectUserId) ?? '1',
        },
    });

    const {fields, append} = useFieldArray({
        control,
        name: 'phoneNumbers',
    });


    const [users, setUsers] = useState<User[]>([]);
    const [groups, setGroups] = useState<Group[]>([]);
    const [isLoading, setIsLoading] = useState(false);


    useEffect(() => {
        if (!isNewContact && contactData) {
            setValue('firstName', contactData.firstName || '');
            setValue('lastName', contactData.lastName || '');
            setValue('email', contactData.email || '');
            setValue('company', contactData.company || '');
            setValue('phoneNumbers', contactData.phoneNumbers.map(val => (
                {msisdn: val.msisdn.toString(), main: val.main}
            )) || [{msisdn: '', main: true}]);
            setValue('availability', contactData.availability || 'PUBLIC');
            setValue('sharedForUsers', contactData.sharedForUsers || []);
            setValue('sharedForGroups', contactData.sharedForGroups || []);
            setGroups(contactData?.sharedForGroups);
            setUsers(contactData?.sharedForUsers);
        }
    }, [contactData, isNewContact]);


    const handleMenageAccessChange = (res: boolean) => {

        if (!res) {
            setUsers(getValues('sharedForUsers'));
            setGroups(getValues('sharedForGroups'));
        }
        setValue(
            'sharedForUsers',
            users,
        )
        setValue(
            'sharedForGroups',

            groups,
        );
    };

    const handleAddPhoneNumber = () => {
        append({msisdn: '', main: false});
    };


    const addContactTrigger = <Button className='m-6' buttonType='secondary'
                                      label='Add a team member' />;
    const menageAccessTrigger = <Button buttonType='secondary' label='Share access' size='small' leftIcon='settings'/>;
    const modalContent = <AddContactModal selectedUsers={getValues('sharedForUsers')}
                                          selectedGroups={getValues('sharedForGroups')} setUsers={setUsers}
                                          setGroups={setGroups}/>;


    const onSubmit = async (data: any) => {
        try {
            setIsLoading(true);
            const cleanedData = removeEmptyFields(data);

            const formattedData = {
                ...cleanedData,
                sharedForUsers: cleanedData.sharedForUsers.map((user: any) => user.userId),
                sharedForGroups: cleanedData.sharedForGroups.map((group: any) => group.groupId),
                phoneNumbers: cleanedData.phoneNumbers.map((phone: any) => ({
                    msisdn: Number(phone.msisdn),
                    main: phone.main,
                })),
            };

            if (isNewContact) {
                await createContact(formattedData);
            } else {
                await updateContact({...formattedData, contactId: Number(id)});
            }
            navigate('/contacts');
        } catch (error) {
            console.error('Error submitting contact form:', error);
        } finally {
            setIsLoading(false);
            if (isNewContact) {
                navigate('/contacts');
            } else {
                navigate(`/contacts/details/${id}`);
            }
        }
    };

    const handleErrors = () => {
        if (errors.phoneNumbers && Array.isArray(errors.phoneNumbers)) {
            const firstInvalidPhoneIndex = errors.phoneNumbers.findIndex(
                (phoneError: any) => phoneError?.msisdn
            );
            if (firstInvalidPhoneIndex !== undefined && firstInvalidPhoneIndex !== -1) {
                const element = document.getElementById(`phoneNumbers.${firstInvalidPhoneIndex}.msisdn`);
                const field = document.querySelector<HTMLInputElement>(`[name="phoneNumbers.${firstInvalidPhoneIndex}.msisdn"]`);
                field?.focus();
                element?.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
                return;
            }
        }

        const errorField = Object.keys(errors)[0] as keyof typeof errors;
        if (errorField) {
            setFocus(errorField as any);
        }
    };

    const removeNumber = (res: boolean, index: number) => {
        if (!res) return;
        const newPhoneNumbers = [...getValues('phoneNumbers')];
        newPhoneNumbers.splice(index, 1);
        setValue('phoneNumbers', newPhoneNumbers);

    }
    const confirmRemoveRef = useRef<{ onSubmit: () => void, openModal: () => void }>(null);
    const removeButton = (index: number) => <div onClick={() => {

        if (watch().phoneNumbers[index].msisdn.length) {
            confirmRemoveRef.current?.openModal();
        } else {
            removeNumber(true, index);
        }
    }}>
        <Icon className='cursor-pointer' iconName='delete' iconType='destructive'
              background={false}/>
    </div>

    return (
        <div className={`w-full `}>
            <div>


                <div className={` ${isMobile ? ' px-4 ' : ' '} `}>

                    <div
                        className={`flex  border-b ${isMobile ? ' mt-6' : '   '}` + clsxMerge(borderStyles({theme}), boxStyles({theme}))}>
                        <div className={`border-r border-grey-75 px-8 py-5 min-w-[345px]`}>
                            <div className='font-semibold  mb-1.5 text-lg'>
                                General information
                            </div>
                            <div className='text-grey-500 text-sm pr-12'>
                                Update name and general information
                            </div>
                        </div>
                        <div className='p-6 grid w-full '>
                            <div className={'block'}>
                                {fields.map((field, index) => (

                                    <div className='flex items-center mb-3' key={field.id}>


                                        <Controller

                                            name={`phoneNumbers.${index}.msisdn`}
                                            control={control}
                                            rules={{
                                                required: 'Phone number is required',
                                                validate: {
                                                    isValid: (value: any) => {
                                                        return isValidPhoneNumber(`+${value}`) || 'Phone number is not valid'
                                                    }
                                                },
                                            }}
                                            render={({field}) => (

                                                <PhoneNumberInput
                                                    phoneNumber={field.value}
                                                    onPhoneNumberChange={(value: any) => field.onChange(value)}
                                                    error={errors.phoneNumbers?.[index]?.msisdn ? errors.phoneNumbers[index].msisdn.message : undefined}
                                                    onBlur={field.onBlur}
                                                    name={field.name}
                                                />


                                            )}
                                        />

                                        {watch().phoneNumbers?.length > 1 && removeButton(index)}
                                        <ChModal
                                            callback={(res: boolean) => {
                                                removeNumber(res, index);
                                            }}
                                            title={'Delete phone number'}

                                            content={<div className='py-6'>
                                                Are you sure you want to delete this phone number?
                                            </div>}
                                            primaryLabel='Delete'
                                            secondaryLabel='Cancel'
                                            modalType='destructive'
                                            ref={confirmRemoveRef}

                                        />
                                    </div>
                                ))}

                                <div className='flex justify-end'>
                                    <Button className='pr-0' onClick={handleAddPhoneNumber} size='small' buttonType='textPrimary'
                                            label='Add another phone number'
                                            leftIcon='add'/>
                                </div>
                            </div>

                            <div>
                                <form className='grid gap-6' onSubmit={handleSubmit(onSubmit)}>
                                    <div className="flex justify-between">
                                    <div className="w-[47%]">
                                    <Controller
                                        name="firstName"
                                        control={control}
                                        rules={{required: 'First name is required'}}
                                        render={({field}) => (
                                            <ChInput
                                                {...field}
                                                value={field.value}
                                                callback={(value: any) => field.onChange(value)}
                                                className='max-w-full py-3 pr-4 text-sm'
                                                label='First name'

                                                error={errors.firstName ? errors.firstName.message : undefined}
                                            />
                                        )}
                                    />
                                    </div>
                                    <div className="w-[47%]">
                                    <Controller
                                        name="lastName"
                                        control={control}
                                        rules={{required: 'Last name is required'}}
                                        render={({field}) => (
                                            <ChInput
                                                {...field}
                                                value={field.value}

                                                callback={(value: any) => field.onChange(value)}
                                                className='max-w-full py-3 pr-4 text-sm'
                                                label='Last name'
                                                error={errors.lastName ? errors.lastName.message : undefined}
                                            />
                                        )}
                                    />
                                    </div>
                                    </div>
                                    <Controller
                                        name="email"
                                        control={control}
                                        rules={{
                                            validate: {
                                                isValid: (value: any) => value ? (validateEmail(value) || 'Invalid email format') : true,
                                            },
                                        }}
                                        render={({field}) => (
                                            <ChInput
                                                {...field}
                                                value={field.value}
                                                callback={(value: any) => field.onChange(value)}
                                                className='max-w-full py-3 pr-4 text-sm'

                                                label='Email address'
                                                error={errors.email ? errors.email.message : undefined}
                                            />
                                        )}
                                    />
                                    <Controller
                                        name="company"
                                        control={control}
                                        render={({field}) => (
                                            <ChInput
                                                {...field}
                                                value={field.value}
                                                callback={(value: any) => field.onChange(value)}
                                                className='max-w-full py-3 pr-4 text-sm'

                                                label='Company'
                                                error={errors.company ? errors.company.message : undefined}
                                            />
                                        )}
                                    />
                                </form>
                            </div>
                        </div>
                    </div>

                    <div className={`flex  border-b  ${isMobile ? ' mt-6' : ' '}` + clsxMerge(borderStyles({theme}))}>
                        <div className=" border-r border-grey-75 px-8 py-5 min-w-[345px]">
                            <div className='font-semibold text-lg '>
                                Contact availability
                            </div>
                            <div className='text-grey-500 text-sm '>
                                Public or private
                            </div>
                        </div>
                        <div className={`p-6 `}>
                            <ChRadio alignLeft={false} value={watch('availability')} className={'flex-col'}
                                     radioGroup={RadioGroup}
                                     callback={(value: string) => {
                                         console.log(value);
                                         setValue('availability', value)
                                     }}/>
                        </div>
                    </div>

                    {watch('availability') === 'SHARED' &&
                        <div className={`flex  border-b    ` + clsxMerge(borderStyles({theme}))}>
                            <div className="border-r border-grey-75 px-8 py-5 min-w-[345px] w-[345px]">
                                <div className='flex-col   flex justify-between'>
                                    <div className="font-semibold text-lg">
                                        Share access
                                    </div>
                                    <div className=' text-grey-500 text-sm mb-5'>
                                        Give other members in your workspace access to this phone number
                                    </div>
                                    {Boolean(watch().sharedForUsers?.length || watch().sharedForGroups?.length) && <div>
                                        <ChModal callback={handleMenageAccessChange} title={'Share access'}
                                                 trigger={menageAccessTrigger} content={modalContent}
                                                 secondaryLabel='Cancel'
                                                 primaryLabel='Done'/>
                                    </div>
                                    }
                                </div>
                            </div>
                            <div className="w-full">
                                {Boolean(!watch().sharedForUsers?.length && !watch().sharedForGroups?.length) &&
                                    <div className={`p-6  flex justify-center  `}>
                                        <ChModal title={'Share with people'} callback={handleMenageAccessChange}
                                                 trigger={addContactTrigger} content={modalContent}
                                                 secondaryLabel='Cancel'
                                                 primaryLabel='Done'/>
                                    </div>}

                                {Boolean(watch().sharedForUsers?.length) && <>
                                    <div className={`px-8 py-3 w-full text-xs font-semibold border-grey-75 `}>
                                        Users
                                    </div>
                                    <div>
                                        {(getValues().sharedForUsers?.map((user: any, index: number) => {

                                            return (
                                                <div className={`border-t  w-full border-grey-75 `} key={index}>
                                                    <div className='flex gap-3  items-center px-8 py-4'>
                                                        <Avatar size="md" type='initials'
                                                                color={user.iconColor ?? Colors.auto}
                                                                name={user.firstName + ' ' + user.lastName}/>{user.firstName + ' ' + user.lastName}
                                                    </div>
                                                </div>
                                            )
                                        }))
                                        }
                                    </div>
                                </>}


                                {Boolean(watch().sharedForGroups?.length) && <>
                                    <div className={`px-8 py-3 border-y w-full text-xs font-semibold border-grey-75 `}>
                                        Groups
                                    </div>
                                    <div>
                                        {getValues().sharedForGroups.map((group: any, index: number) => {
                                                return (
                                                    <div className={`border-b border-grey-75 `} w-full key={index}>
                                                        <div className='flex gap-3  items-center px-8 py-4'>
                                                            <Avatar size="md" type='icon'
                                                                    color={group.iconColor ?? Colors.auto}
                                                                    icon='home'/>{group.name}
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        )}
                                    </div>
                                </>
                                }
                            </div>
                        </div>}


                    <div className={` flex justify-end relative`}>
                        {
                            !isNewContact && <DeleteContactModal/>
                        }

                        <div className={`flex gap-3 justify-end ${isMobile ? ' m-0' : ' mx-8 my-6'}`}>
                            <Link to={'/contacts'}>
                                <Button buttonType='secondary' size='large' label={'Cancel'}></Button>
                            </Link>
                            <Button disabled={isLoading} size='large' onClick={handleSubmit(onSubmit, handleErrors)}

                                    label={isNewContact ? 'Add contact' : 'Save changes'}></Button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default AddEditContact;
