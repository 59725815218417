import { Avatar } from 'ch3-ui-lib';
import {getFlag, getFormatedPhoneNumber} from '../../utilis/msisdnUtilis';
import {duration, getTimeAgo} from '../../utilis/timeUtilis';
import {useState} from "react";
import {getCallType} from "../../utilis/callsUtilis";
import {ClickToCall} from "../../components/ClickToCall/ClickToCall";
import {FlagImage} from "react-international-phone";
import listEmpty from "../../assets/list-empty.svg";

import { clsxMerge } from '../../utilis/classNameUtils';
import { borderStyles, selectedBgStyles, textStyles } from './helpers';
import {useSelector} from "react-redux";
import {Call, useLazyFetchCallRecordQuery} from "../../store/features/calls/callsService";
import AudioPlayer from "../../components/AudioPlayer/AudioPlayer";

interface RecentCallsProps {
    groupedCalls: { [key: string]: Call[] };
}

const RecentCallsDetails = ({ groupedCalls }: RecentCallsProps) => {

    const [selectedCallId, setSelectedCallId] = useState<number | null>(null);
    const theme = useSelector((state: any) => state.themeSwitcher.theme);

    const [fetchedRecordings, setFetchedRecordings] = useState<any>({});
    const [triggerFetch] = useLazyFetchCallRecordQuery();

    const handleCallClick = async (callId: number, recordId?: number) => {
        setSelectedCallId(callId === selectedCallId ? null : callId);

        if (recordId) {
            try {
                const { data: recordingUrl } = await triggerFetch(recordId);
                setFetchedRecordings((prevState: Record<number, string>) => ({
                    ...prevState,
                    [recordId]: recordingUrl,
                }));
            } catch (error) {
                console.error('Failed to fetch recording:', error);
            }
        }
    };

     function getCallDescription(call: Call): any {
        if (call.forwardedTo) {
            return 'Call forwarded to ' + getFormatedPhoneNumber(call.forwardedTo, true);
        }
        if (call.type === 'OUTBOUND') {
            return 'Outgoing call';
        }
        if (call.type === 'INBOUND' && !call.bridgetAt) {
            return 'Missed call';
        }
        if (call.type === 'INBOUND' && call.bridgetAt) {
            return 'Incoming call';
        }
        return 'Unknown call type';
    }

    if(!groupedCalls) return null;
    const sortedGroups = Object.keys(groupedCalls).sort((a, b) => new Date(b).getTime() - new Date(a).getTime());

    return (
        <div className={` w-full  border-t ` + clsxMerge(borderStyles({theme}))}>

            {sortedGroups.map(date => {
                if (!groupedCalls[date].length) return null;
                return (
                    <div key={date}>
                        {groupedCalls[date].map((call: Call) => (
                            <div className={clsxMerge(textStyles({theme})) + ` cursor-pointer ${selectedCallId == call.callId && clsxMerge(selectedBgStyles({theme}))} ` } key={call.callId} onClick={() => handleCallClick(call.callId, call?.recordingId)}>

                                <div className={`py-3 px-6 flex items-center border-b border-grey-100 hover:bg-grey-50 hover:rounded-tl-lg hover:rounded-tr-lg `}>

                                    <div className='flex items-center w-1/2 text-sm font-medium'>
                                        {getCallType(call.type, call.bridgetAt, call.forwardedTo)}
                                        {getCallDescription(call)}
                                    </div>

<div className=' ml-auto flex'>
                                    <div className={'flex items-center'}>
                                        <p className="text-grey-500 text-xs font-medium">{getTimeAgo(new Date(call.startedAt))}</p>
                                    </div>
                                    {Boolean(call.exposedMsisdn) &&

                                    <div className='   w-[25px] ml-4'>
                                        <div className="flex items-center">
                                        <Avatar icon={call.exposedMsisdn.icon} size="xxs" type='icon' color={call.exposedMsisdn.iconColor}
                    name='phone number icon'/>
                                        </div>

                                    </div>
                                    
                        }
                        </div>
                                </div>
                                {selectedCallId == call.callId && getCallDetails(call, theme, fetchedRecordings)}
                            </div>
                        ))}
                    </div>
                );
            })}
            {sortedGroups.length === 0 &&
                    <div className='flex text-center  justify-center '>
                        <div className='w-[450px]  m-14'>
                            <div className='flex  justify-center w-full'>
                                <img className='m-4' src={listEmpty}/>
                            </div>
                            <h1 className='font-bold'>
                                No calls yet
                            </h1>
                            <div className='mb-6 mt-2'>
                                Start making calls and they'll show up here.
                            </div>
                        </div>
                    </div>
                }
        </div>
    );
};

const getCallDetails = (selectedCall: Call, theme: any, fetchedRecordings:any) => {


    return (
            <div className={`flex flex-col px-12 pb-4 border-b  border-solid ` + clsxMerge(selectedBgStyles({theme}))} >


                <div className={`flex py-4 w-full  ${selectedCall.user?.firstName && `border-b  border-grey-100 ` + clsxMerge(borderStyles({theme}))} max-md:flex-wrap max-md:max-w-full `}>
                    <div className="my-auto text-sm leading-5 w-[150px] text-grey-700">
                        Phone number
                    </div>

                    <div className=" text-sm font-semibold items-center flex">{Boolean(selectedCall.clientMsisdn) && <> <FlagImage className='w-4 h-4 mr-3'
                                                                                                                     iso2={getFlag(selectedCall.clientMsisdn) ?? 'us'}/> <ClickToCall noPrefix={true} msisdn={selectedCall.clientMsisdn}/></>}</div>


                </div>
                {(selectedCall.bridgetAt )&& <div
                    className={`flex py-4 text-sm  leading-5 border-b border-grey-100  max-md:flex-wrap max-md:max-w-full ` }>
                    <div className="text-sm  w-[150px] text-grey-700">Call length</div>
                    <div
                        className="font-semibold">     {duration(selectedCall.bridgetAt, selectedCall.finishedAt)}</div>
                </div>}
                { selectedCall.user?.firstName && <div
                    className={`flex  py-4 text-sm leading-5 max-md:flex-wrap max-md:max-w-full ${selectedCall.recordingId ? 'border-b  border-grey-100  ' : ''} `}>
                    <div className="text-sm  w-[150px] text-grey-700">User</div>
                    <div
                        className="font-semibold">{selectedCall.user?.firstName + ' ' + selectedCall.user?.lastName}</div>
                </div>}
                {fetchedRecordings[selectedCall.recordingId] && <div className=" text-sm pt-4 max-md:max-w-full">
                    Recordings
              <div className='mt-4'>
                        {selectedCall.recordingId && <AudioPlayer src={fetchedRecordings[selectedCall.recordingId]}/>}
                    </div>
                </div>}
            </div>

        );
}


export default RecentCallsDetails;
