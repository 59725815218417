import DialerHeader from "./components/DialerHeader";
import React, {useEffect, useState} from "react";
import Dialer from "./Dialer";
import Settings from "./Settings";
import AudioSettings from "./pages/AudioSettings";
import {MemoryRouter, Routes, Route} from 'react-router-dom';
import {RootState, useAppDispatch} from "../../store/store";
import {toggleDialerHidden} from "../../store/features/calls/dialerSlice";
import {useSelector} from "react-redux";

import useMediaQuery from "../../utilis/screenWidthUtils.ts";
import { clsxMerge } from "../../utilis/classNameUtils.ts";
import { bgStyles, borderStyles } from "./helpers.tsx";

interface DialerWrapperProps {
    isInCalls: boolean;
}

export const DialerWrapper = ({isInCalls}: DialerWrapperProps) => {
    const dispatch = useAppDispatch()
    const isMobile = useMediaQuery('(max-width: 960px)');

    const incomingCall = useSelector((state: RootState) => state.dialer.incomingCall);
    const isDialerHidden = useSelector((state: RootState) => state.dialer.isDialerHidden);

    const [hiddenDialerCalling, setHiddenDialerCalling] = useState(false);
    const theme = useSelector((state: any) => state.themeSwitcher.theme);

    useEffect(() => {
        if (incomingCall && isDialerHidden) {
            setHiddenDialerCalling(true);
        } else {
            setHiddenDialerCalling(false);
        }
    }, [incomingCall, isDialerHidden]);

    const showKeyboard = () => {
        dispatch(toggleDialerHidden());
    }



    return (
        <div className={`${isMobile ? 'hidden' : ' '}`}>

            <div onClick={() => {
                isDialerHidden &&
                showKeyboard();
            }}
                 className={` justify-center  border-x border-solid h-[calc(100vh-var(--header-height))]  ${isDialerHidden ? ' w-[80px] ' : ' w-[320px] '} ${hiddenDialerCalling ? ' !border-2 !border-primary-500' : 'min-w-320'} ` + clsxMerge(borderStyles({theme}), bgStyles({theme}))}>
                <MemoryRouter>
                    <DialerHeader/>

                    <React.StrictMode>
                        <Routes>
                            <Route path="/settings" element={<Settings/>}/>
                            <Route path="/settings/audio" element={<AudioSettings/>}/>
                        </Routes>
                    </React.StrictMode>
                </MemoryRouter>

                <Dialer isInCalls={isInCalls}/>


            </div>
        </div>
    )
}
