import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import {
    persistReducer,
    persistStore,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { useDispatch } from 'react-redux';
import logger from 'redux-logger';
import * as Sentry from '@sentry/react';
import dialerReducer from './features/calls/dialerSlice';
import toastReducer from './features/Notifications/notificationsSlice';
import filtersReducer from './features/Filters/filtersSlice';
import themeSwitcherReducer from './features/ThemeSwitcher/themeSwitcherSlice'
import authReducer from './features/Auth/authSlice';
import {callsApi} from "./features/calls/callsService";
import {contactsApi} from "./features/Contacts/contactsService";
import {msisdnsApi} from "./features/Msisdns/msisdnService";
import {usersApi} from "./features/Users/userService";
import {groupsApi} from "./features/groups/groupsService";
import {teamApi} from "./features/Team/teamService";
import {msisdnSettingsApi} from "./features/MsisdnSettings/msisdnSettingsService";
import {profileSettingsApi} from "./features/ProfileSettings/profileSettingsService";
import {callQueueApi} from "./features/CallQueue/CallQueueService";
import {integrationApi} from "./features/integrations/integrationsService"
import {billingApi} from "./features/Billing/BillingService";



const reducers = combineReducers({
    auth: authReducer,
    [callsApi.reducerPath]: callsApi.reducer,
    dialer: dialerReducer,
    [groupsApi.reducerPath]: groupsApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [msisdnsApi.reducerPath]: msisdnsApi.reducer,
    [contactsApi.reducerPath]: contactsApi.reducer,
    [msisdnSettingsApi.reducerPath]: msisdnSettingsApi.reducer,
    toast: toastReducer,
    filters: filtersReducer,
    [teamApi.reducerPath]: teamApi.reducer,
    [profileSettingsApi.reducerPath]: profileSettingsApi.reducer,
    [billingApi.reducerPath]: billingApi.reducer,
    [callQueueApi.reducerPath]: callQueueApi.reducer,
    themeSwitcher: themeSwitcherReducer,
    [integrationApi.reducerPath]: integrationApi.reducer,
});


const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['auth', 'users'],
};


const persistedReducer = persistReducer(persistConfig, reducers);


const sentryReduxEnhancer = Sentry.createReduxEnhancer({});



const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        })
            .concat(logger)
            .concat(callsApi.middleware)
            .concat(contactsApi.middleware)
            .concat(msisdnsApi.middleware)
            .concat(usersApi.middleware)
            .concat(groupsApi.middleware)
            .concat(teamApi.middleware)
            .concat(msisdnSettingsApi.middleware)
            .concat(profileSettingsApi.middleware)
            .concat(callQueueApi.middleware)
            .concat(integrationApi.middleware)
            .concat(billingApi.middleware),
    enhancers: (getDefaultEnhancers) => {
        return getDefaultEnhancers().concat(sentryReduxEnhancer);
    },
});


const persistor = persistStore(store);


setupListeners(store.dispatch);


export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;


export const useAppDispatch: () => AppDispatch = useDispatch;

export { store, persistor };
