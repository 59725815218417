import { createApi } from '@reduxjs/toolkit/query/react';
import { showToast } from '../Notifications/notificationsSlice';
import { baseQuery } from '../../baseQuery';
import {Group} from "../groups/groupsService";
import {Msisdn} from "../Msisdns/msisdnService";


interface UserSettings {
    // userId: number,
    firstName: string,
    lastName: string,
    position: string,
    email: string,
    missedCallEmailNotificationEnabled: boolean,
    groups: Group[],
    msisdns: Msisdn[],
}

export const profileSettingsApi = createApi({
    reducerPath: 'profileSettingsApi',
    baseQuery,
    tagTypes: ['Profile'],
    endpoints: (builder) => ({
        getUserProfile: builder.query<UserSettings, string | null>({
            query: (userId) => ({
                url: `/user/profile/${userId}`,
            }),
        }),
        getUserProfileNotifications: builder.query<{ missedCallEmailNotificationEnabled: boolean }, string | null>({
            query: (userId) => ({
                url: `/user/profile/${userId}/notifications`,
            }),
        }),
        updateUserProfile: builder.mutation<void, { data: Partial<UserSettings>, userId: string | null }>({
            query: ({data, userId}) => ({
                url: `/user/profile/${userId}`,
                method: 'PATCH',
                body: data,
            }),
            async onQueryStarted( {},{ dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    dispatch(showToast({ description: 'Profile updated successfully!', type: 'success' }));
                } catch {
                    dispatch(showToast({ description: 'Failed to update profile!', type: 'error' }));
                }
            },
            invalidatesTags: ['Profile'],
        }),
        updateUserProfileNotifications: builder.mutation<void, { userId: string; missedCallEmailNotificationEnabled: boolean }>({
            query: ({ userId, missedCallEmailNotificationEnabled }) => ({
                url: `/user/profile/${userId}/notifications`,
                method: 'PATCH',
                body: {userId, missedCallEmailNotificationEnabled },
            }),
            async onQueryStarted({  }, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    dispatch(showToast({ description: 'Notifications state updated successfully!', type: 'success' }));
                } catch {
                    dispatch(showToast({ description: 'Failed to change notifications state!', type: 'error' }));
                }
            },
        }),
    }),
});


export const {
    useGetUserProfileQuery,
    useUpdateUserProfileMutation,
    useGetUserProfileNotificationsQuery,
    useUpdateUserProfileNotificationsMutation,
} = profileSettingsApi;
