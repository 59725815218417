import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../../baseQuery';


interface BillingReportRequest {
    dateFrom: string;
    dateTo: string;
    type: string;
}

export const billingApi = createApi({
    reducerPath: 'billingApi',
    baseQuery,
    endpoints: (builder) => ({
        generateBillingReport: builder.mutation<any, BillingReportRequest>({
            query: (data) => ({
                url: '/api/v1/reports',
                method: 'POST',
                body: data,
                responseHandler: (response: any) => response.blob(),

            }),
            transformResponse: (data: Blob) => {
                const pdfBlob = new Blob([data]);
                return URL.createObjectURL(pdfBlob);
            },
        }),
    }),
});

export const {
    useGenerateBillingReportMutation,
} = billingApi;
