import {ChColumn, Loader} from "ch3-ui-lib";
import {sortBy} from "lodash";
import {Queue, useGetQueuesQuery} from "../../store/features/CallQueue/CallQueueService";
import {Link} from "react-router-dom";
import {useEffect, useState} from "react";

export const CallQueuesList = () => {
    const { data: callQueue, isLoading } = useGetQueuesQuery();
    const [sortedQueues, setSortedQueues] = useState<Queue[]>([]);
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');

    useEffect(() => {
        if (callQueue) setSortedQueues(callQueue);
    }, [callQueue]);

    const sortByName = () => {
        const sorted = sortOrder === 'asc'
            ? sortBy(sortedQueues, 'name')
            : sortBy(sortedQueues, 'name').reverse();

        setSortedQueues(sorted);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    };

    if (isLoading) {
        return (
            <div className="flex w-full">
                <Loader />
            </div>
        );
    }

    return (
        <div className="w-full">
            <table className="w-full">
                <thead>
                <tr>
                    <th className="px-8 w-full">
                        <ChColumn
                            // className="w-full"
                            propertyName="NAME"
                            callback={sortByName}
                            label={'Name' }
                        />
                    </th>
                </tr>
                </thead>
                <tbody>
                {sortedQueues.map((queue: Queue) => (
                    <tr
                        key={queue.id}
                        className="hover:bg-grey-50 w-full block border-b border-grey-75"
                    >
                        <Link
                            className="px-8 w-full block font-medium font-sm"
                            to={`/admin/callQueues/${queue.id}`}
                        >
                            <td className="py-8 w-full">{queue.name}</td>
                        </Link>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};
