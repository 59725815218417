import {Button, Icon} from "ch3-ui-lib";
import {Link} from "react-router-dom";

interface IntegrationDescriptionProps {
    integrationName: string;
    title: string;
    helpDescription: string;
    buttonLabel: string;
    startText: string;
    idText: string;
    scopeText: string;
    scopeCopyText: string;
    description_1:string;
    description_2: string;
    description_3:string;
    description_4:string;
    attentionDescription: string;
    instructionText: string;
    adminPanelText: string;
    adminPanelLink: any;
    id: string;

}

export const IntegrationDescription = ({
                                           
                                           buttonLabel,
                                           startText,
                                           idText,
                                           scopeText,
                                           scopeCopyText,
                                           title,
                                           description_1,
                                           description_2,
                                           description_3,
                                           description_4,
                                           attentionDescription,
                                           helpDescription,
                                           adminPanelText,
                                           adminPanelLink,
                                           id
                                       }:IntegrationDescriptionProps) => {  
      
      function copyToClipBoard(text:any){
        navigator.clipboard.writeText(text)
      }


    return (
        <>


                <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/c10f5496d29a21d114a2b15cfc115b4b1956bf568b5672e4ef086d81faafac39?placeholderIfAbsent=true&apiKey=b80f1a000b1b49588dd2774515b82ddf"
                    alt="Google Sync Illustration"
                    className="object-contain aspect-[0.9] h-full "
                />
                <section className="flex flex-col min-w-[240px] pr-8 ">
                    <div className="flex flex-col w-full text-black">
                        <div className="flex flex-col mb-8 w-full ">
                            <p className="text-base leading-6 w-full  mb-6">{startText}</p>
                            <ul className="list-disc pl-6 mb-8">
                                <li className="mb-2.5">
                                    <p className="flex items-center">{idText}<span className="font-semibold ml-2 text-md">{id}</span><span className="hover:text-primary-400 cursor-pointer" onClick={() => copyToClipBoard(id)}><Icon iconName="content_copy"></Icon></span></p>
                                </li>
                                <li>
                                    <div className="flex items-center"><p>{scopeText}</p><span className="hover:text-primary-400 cursor-pointer" onClick={() => copyToClipBoard(scopeCopyText)}><Icon iconName="content_copy"></Icon></span></div>
                                    <p className="text-wrap inline max-w-[300px]">{scopeCopyText}</p>
                                </li>
                            </ul>
                            <h2 className="text-2xl font-bold leading-none l">{title}</h2>
                            <ul className="list-decimal pl-6 mb-2">
                                <li>
                                    <p className="my-4 font-medium text-base leading-6  ">{description_1}<a target="_blank" className="ml-1 underline text-primary-400" href={adminPanelLink}>{adminPanelText}</a> </p>
                                </li>
                                <li>
                                    <p className="my-4 text-base font-medium leading-6  ">{description_2}</p>
                                </li>
                                <li>
                                    <p className="my-4 text-base font-medium leading-6 ">{description_3}</p>
                                </li>
                                <li>
                                    <p className="my-4 text-base font-medium leading-6  ">{description_4}</p>
                                </li>
                            </ul>
                            <div className="mb-4 text-sm p-4 border border-primary-400 bg-primary-50 rounded">{attentionDescription}</div>
                            <div className="mb-4">{helpDescription}</div>
                        </div>
                        <div className='flex justify-end'>
                            <Link to='connect'>
                                <Button className='' buttonType='primary' label={buttonLabel}/>
                            </Link>

                        </div>

                    </div>
                </section>



        </>
    );
}
