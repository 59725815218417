import { createApi } from '@reduxjs/toolkit/query/react';

import {baseQuery} from "../../baseQuery";



export interface Msisdn {
    "msisdnId": number,
    "assigmentScope"?: string,
    "msisdn": number,
    "label": string,
    "icon": string,
    "type": string,
    "iconColor": string
}
export interface MsisdnSelectItem {
    msisdnId: number;
    label: string;
    icon: string;
    type: string;
    msisdn: number;
}
export const msisdnsApi = createApi({
    reducerPath: 'msisdnsApi',
    baseQuery,
    endpoints: (builder) => ({
        fetchMsisdns: builder.query<Msisdn[], string | null>({
            query: (userId) => ({
                url: `/msisdn/user/${userId}`,
            }),
        }),
        getOneMsisdn: builder.query<Msisdn, number>({
            query: (id) => ({
                url: `/msisdn/${id}`,
            }),
        }),
        postMsisdn: builder.mutation<Msisdn, Msisdn>({
            query: (msisdn) => ({
                url: '/msisdn',
                method: 'POST',
                body: msisdn,
            }),
        }),
        patchMsisdn: builder.mutation<Msisdn, { id: number; msisdn: Msisdn }>({
            query: ({ id, msisdn }) => ({
                url: `/msisdn/${id}`,
                method: 'PATCH',
                body: msisdn,
            }),
        }),
        deleteMsisdn: builder.mutation<number, number>({
            query: (id) => ({
                url: `/msisdn/${id}`,
                method: 'DELETE',

            }),
        }),
        getUserMsisdns: builder.query<{ data: MsisdnSelectItem[]; totalCount: number }, { phrase:string  | undefined, userId: string | null }>({
            query: ({phrase, userId}) => ({
                url: `/msisdn/user/${userId}`,
                params: { phrase },

            }),
            transformResponse: (response: MsisdnSelectItem[], meta) => {
                const totalCount = meta?.response?.headers.get('x-total-count');
                return {
                    data: response,
                    totalCount: totalCount ? parseInt(totalCount, 10) : 0
                };
            },
        }),
    }),
});


export const {
    useFetchMsisdnsQuery,
    useGetUserMsisdnsQuery,
} = msisdnsApi;
