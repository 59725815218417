import { createApi } from '@reduxjs/toolkit/query/react';

import { showToast } from '../Notifications/notificationsSlice';

import {baseQuery} from "../../baseQuery";
import {ColorKey} from "../../../utilis/types/Types";
import {User} from "../Users/userService";
import {Msisdn} from "../Msisdns/msisdnService";


export interface GroupDTO {
    id: number;
    name: string;
    description: string | undefined;
    type: string | undefined;
    iconColor: ColorKey;
    members: number[];
    msisdns: number[];
}

export interface Group {
    groupId?: number;
    name: string;
    description: string | undefined;
    type: string | undefined;
    iconColor: ColorKey;
    users: User[];
    msisdns: Msisdn[];
}
export interface GroupListItem {
    groupId: number;
    name: string;
    description: string;
    type: string;
    iconColor: ColorKey;
}
export interface FetchGroupsResponse {

    group: GroupListItem;
    users: User[]
    msisdns: Msisdn[]
}

export interface GroupsList {
    groups: FetchGroupsResponse[];
    totalCount: number;
}


interface ParamsProps {
    phrase?: string;
    lastGroupId?: number;
    lastName?: string;
    sortBy?: string;
    msisdns?: number[]
    order?: string;
    groupId?: number;
    userId: string | null;
}


export const groupsApi = createApi({
    reducerPath: 'groupsApi',
    baseQuery,
    tagTypes: ['Group', 'Groups', 'AdminGroups'],
    endpoints: (builder) => ({
        fetchGroups: builder.query<GroupsList, ParamsProps>({
            query: ({ userId, phrase, lastGroupId, lastName, sortBy, order, msisdns, groupId }) => ({
                url: `/group/${userId}`,
                params: { phrase, lastGroupId, lastName, sortBy, order, msisdns, groupId },
            }),
            transformResponse: (response: FetchGroupsResponse[], meta) => {
                const totalCount = meta?.response?.headers.get('x-total-count');
                return {
                    groups: response || [],
                    totalCount: totalCount ? parseInt(totalCount, 10) : 0,
                };
            },
            providesTags: (result) =>
                result
                    ? [
                        ...result.groups.map(({ group }) => ({
                            type: 'Group' as const,
                            id: group.groupId,
                        })),
                        { type: 'Groups', id: 'LIST' },
                    ]
                    : [{ type: 'Groups', id: 'LIST' }],
        }),
        fetchGroupsAdmin: builder.query<GroupsList, ParamsProps>({
            query: ({ phrase, lastGroupId, lastName, sortBy, order, msisdns, groupId, userId }) => ({
                url: `/api/admin/v1/groups/${userId}`,
                params: { phrase, lastGroupId, lastName, sortBy, order, msisdns, groupId },
            }),
            transformResponse: (response: FetchGroupsResponse[], meta) => {
                const totalCount = meta?.response?.headers.get('x-total-count');
                return {
                    groups: response || [],
                    totalCount: totalCount ? parseInt(totalCount, 10) : 0,
                };
            },
            providesTags: (result) =>
                result
                    ? [
                        ...result.groups.map(({ group }) => ({
                            type: 'Group' as const,
                            id: group.groupId,
                        })),
                        { type: 'AdminGroups', id: 'LIST' },
                    ]
                    : [{ type: 'AdminGroups', id: 'LIST' }],
        }),
        fetchAdminGroupById: builder.query<FetchGroupsResponse, { groupId: number; userId: string | null }>({
            query: ({ groupId, userId }) => ({
                url: `/api/admin/v1/groups/${userId}`,
                params: { groupId },
            }),
            transformResponse: (response: any) => {
                return (response as FetchGroupsResponse[])[0];
            },
            providesTags: (group) =>
                group && group.group
                    ? [{ type: 'Group', id: group.group.groupId }]
                    : [],
        }),
        fetchGroupById: builder.query<FetchGroupsResponse, { groupId: number; userId: string | null }>({
            query: ({ groupId, userId }) => ({
                url: `/group/${userId}`,
                params: { groupId },
            }),
            transformResponse: (response: any) => {
                return (response as FetchGroupsResponse[])[0];
            },
            providesTags: (group) =>
                group && group.group
                    ? [{ type: 'Group', id: group.group.groupId }]
                    : [],
        }),
        addGroup: builder.mutation<GroupDTO, { newGroup: Group }>({
            query: ({ newGroup }) => ({
                url: `/api/admin/v1/groups`,
                method: 'POST',
                body: {
                    ...newGroup,
                    members: newGroup.users.map((user: User) => user.userId),
                    msisdns: newGroup.msisdns.map((msisdn: Msisdn) => msisdn.msisdnId),
                },
            }),
            invalidatesTags: [
                { type: 'Groups', id: 'LIST' },
                { type: 'AdminGroups', id: 'LIST' },
            ],
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    dispatch(showToast({ description: 'Group added successfully!', type: 'success' }));
                } catch {
                    dispatch(showToast({ description: 'Failed to add group!', type: 'error' }));
                }
            },
        }),
        updateGroup: builder.mutation<GroupDTO, Group>({
            query: (updatedGroup) => ({
                url: `/api/admin/v1/groups/${updatedGroup.groupId}`,
                method: 'PATCH',
                body: {
                    ...updatedGroup,
                    members: updatedGroup.users.map((user: User) => user.userId),
                    msisdns: updatedGroup.msisdns.map((msisdn: Msisdn) => msisdn.msisdnId),
                },
            }),
            invalidatesTags: (updatedGroup) => [
                { type: 'Group', id: updatedGroup?.id },
                { type: 'Groups', id: 'LIST' },
                { type: 'AdminGroups', id: 'LIST' },
            ],
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    dispatch(showToast({ description: 'Group updated successfully!', type: 'success' }));
                } catch {
                    dispatch(showToast({ description: 'Failed to update group!', type: 'error' }));
                }
            },
        }),
        deleteGroup: builder.mutation<number, number>({
            query: (groupId) => ({
                url: `/api/admin/v1/groups/${groupId}`,
                method: 'DELETE',
            }),
            invalidatesTags: (groupId) => [
                { type: 'Group', id: groupId },
                { type: 'Groups', id: 'LIST' },
                { type: 'AdminGroups', id: 'LIST' },
            ],
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled;
                    dispatch(showToast({ description: 'Group deleted successfully!', type: 'success' }));
                } catch {
                    dispatch(showToast({ description: 'Failed to delete group!', type: 'error' }));
                }
            },
        }),
        loadMoreGroups: builder.query<GroupsList, ParamsProps>({
            query: ({ phrase, lastGroupId, lastName, sortBy }) => ({
                url: '',
                params: { phrase, lastGroupId, lastName, sortBy },
            }),
            providesTags: [{ type: 'Groups', id: 'LIST' }], // Use the same tag as fetchGroups
        }),


    }),
});


export const {
    useFetchGroupsQuery,
    useFetchAdminGroupByIdQuery,
    useFetchGroupByIdQuery,
    useAddGroupMutation,
    useUpdateGroupMutation,
    useDeleteGroupMutation,
    useFetchGroupsAdminQuery,
} = groupsApi;
