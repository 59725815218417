import {Button, Datepicker} from "ch3-ui-lib";
import useMediaQuery from "../../utilis/screenWidthUtils";
import {useState} from "react";
import {useGenerateBillingReportMutation} from "../../store/features/Billing/BillingService";

export const BillingReport = () => {

    const  [dateSelected, setDateSelected] = useState<any>(new Date());

    const [generateBillingReport] = useGenerateBillingReportMutation();

    const isMobile = useMediaQuery('(max-width: 960px)');



    const handleDataChange = (value: any) => {

        const month =  new Date(value.valueOf()).toISOString();
        setDateSelected(month)
    }

    const getFirstAndLastDayOfMonth = (date: Date) => {
        const selectedDate = new Date(date);
        const dateFrom = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 1).toISOString();
        const dateTo = new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, 0).toISOString();

        return { dateFrom, dateTo };
    };

    const handleDownload = async () => {
        const {dateFrom, dateTo} = getFirstAndLastDayOfMonth(dateSelected);
        const url = await generateBillingReport({dateFrom, dateTo, type: 'BILLING_COSTS'}).unwrap();


        const link = document.createElement('a');
        link.href = url;
        const dateObj = new Date(dateSelected);
        const formattedDate = `${(dateObj.getMonth() + 1).toString().padStart(2, '0')}/${dateObj.getFullYear()}`;
        link.download = `BillingReport-${formattedDate}.xlsx`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);


        window.URL.revokeObjectURL(url);
    }
    return (
        <div className='w-full '>
            <div className={'p-6 ' + `${isMobile ? ' px-4' : ''}`}>

                <div className='flex justify-between'>
                    <div className='w-[230px]'>
                        <p className='test-base font-semibold'>Billing report</p>
                        <p className='text-sm'>
                            Download a detailed billing summary for the selected month.
                        </p>
                    </div>
                    <div>
                        <div className='text-sm mb-1'>
                            Select a month to download
                        </div>
                        <Datepicker
                            onlyMonthPicker={true}
                            onDateConfirm={handleDataChange}
                            type='single' />
                    </div>

                    <div className='flex items-center'>
                        <Button onClick={handleDownload} label='Download report' buttonType='primary' className='px-5 py-2' />

                    </div>

                </div>

            </div>
        </div>

);
}

