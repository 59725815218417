
import {Link} from "react-router-dom";
import {useState, useEffect} from "react";
import {Avatar, Button, ChInput, Icon, Loader, Switch} from "ch3-ui-lib";
import {mobileWidth, tabletWidth} from "../../utilis/screenWidthUtils.ts";
import {useSelector} from "react-redux";
import {useForm, Controller} from "react-hook-form";
import {Colors} from "../../utilis/types/Types";
import { clsxMerge } from "../../utilis/classNameUtils.ts";
import { borderStyles, boxStyles, textStyles } from "./helpers.tsx";
import {
    useGetUserProfileNotificationsQuery,
    useGetUserProfileQuery,
    useUpdateUserProfileMutation,
    useUpdateUserProfileNotificationsMutation
} from "../../store/features/ProfileSettings/profileSettingsService";
import {selectUserId} from "../../store/features/Auth/authSlice";


const profileMenu = [
    {
        icon: 'article',
        label: 'General information',
        subLabel: 'Full name, email, and role',
        value: 'GENERAL_INFORMATION'
    },
    {
        icon: 'call',
        label: 'Phone numbers',
        subLabel: 'Your assigned numbers',
        value: 'PHONE_NUMBERS'
    },
    {
        icon: 'groups',
        label: 'Groups',
        subLabel: 'Your assigned groups',
        value: 'GROUPS'
    },
    {
        icon: 'notifications',
        label: 'Notification',
        subLabel: 'Alerts about calls',
        value: 'NOTIFICATION'
    },
    // {
    //     icon: 'settings',
    //     label: 'Personalization',
    //     subLabel: 'Customize your account settings',
    //     value: 'PERSONALIZATION'
    // }
];


const ProfileSettings = () => {

    const [loading, setLoading] = useState(false)

    const theme = useSelector((state: any) => state.themeSwitcher.theme);

    const [updateProfileSettings] = useUpdateUserProfileMutation();
    const [updateProfileNotifications] = useUpdateUserProfileNotificationsMutation();

    const [state, setState] = useState('GENERAL_INFORMATION');

    const userId = useSelector(selectUserId);

    const {data: profileSettings, isLoading} = useGetUserProfileQuery(userId);
    const {data: profileNotifications} = useGetUserProfileNotificationsQuery(userId);





    const {
        control,
        handleSubmit,
        setValue,
        getValues,
        formState: {errors},
    } = useForm({
        mode: 'onBlur',
        defaultValues: {
            firstName: '',
            lastName: '',
            email: '',
            position: '',
            missedCallEmailNotificationEnabled: false
        },
    });


    useEffect(() => {
        if(!profileSettings) return;
        setValue('firstName', profileSettings.firstName || '');
        setValue('lastName', profileSettings.lastName || '');
        setValue('email', profileSettings.email || '');
        setValue('position', profileSettings.position || '');
        setValue('missedCallEmailNotificationEnabled', profileNotifications?.missedCallEmailNotificationEnabled ?? false)
    }, [profileSettings]);


    const handleSwitchChange = async (field: any ) => {
        field.onChange(!field.value);
        await updateProfileNotifications({
            userId: userId ?? '',
            missedCallEmailNotificationEnabled: !field.value
        })
    }


    const onSubmit = async () => {
        const data = {
            userId: userId,
            firstName: getValues('firstName'),
            lastName: getValues('lastName'),
            position: getValues('position'),
        };

            setLoading(true);
            await updateProfileSettings({ data, userId });
            setLoading(false);

    };
    if (isLoading) {
        return (
            <div className={`flex w-full`}>
                <Loader/>
            </div>


        );
    }

    return (
        profileSettings && <div className='w-full'>
            <div
                className={`mt-4 mr-4 overflow-y-auto flex ${mobileWidth || tabletWidth ? ' px-4' : ''}`}>

                <div
                    className=" w-[300px] min-w-[300px] py-8 flex-col justify-start items-center gap-6 inline-flex mx-8">

                    <div className="flex-col justify-start items-center gap-3 flex">
                        <Avatar size={"xxl"} type='initials'
                                name={profileSettings?.firstName + ' ' + profileSettings?.lastName}
                                color={Colors.auto}/>

                        <div className="flex-col justify-start items-center gap-2 flex">

                            <div
                                className={` text-center text-xl font-semibold  leading-7`}>
                                {profileSettings.firstName} {profileSettings.lastName}
                            </div>
                        </div>
                    </div>


                    <div className={`self-stretch py-4` + clsxMerge(textStyles({theme}))} >
                        {profileMenu.map((item) => (
                            <div key={item.value} onClick={() => setState(item.value)}
                                 className={`cursor-pointer flex gap-6 px-8 py-4 ${state === item.value && theme === 'light' ? 'bg-information-50' : ' '} ${state === item.value && theme === 'dark' ? 'bg-[#393C41]' : ' '}`}>
                                <Icon className={`${theme === 'dark' ? ' text-white' : ' '}`} iconName={item.icon}/>
                                <div className="flex flex-col ">
                                    <div
                                        className={`text-sm font-semibold leading-5 ` + clsxMerge(textStyles({theme}))}>{item.label}</div>
                                    <div
                                        className={`mt-1 text-xs leading-5 ` + clsxMerge(textStyles({theme}))}>{item.subLabel}</div>
                                </div>

                            </div>
                        ))}
                    </div>

                </div>

                <div
                    className=" flex-col justify-start items-center gap-6 inline-flex px-8 w-full overflow-x-hidden">
                    {
                        Boolean(state === 'GENERAL_INFORMATION') &&
                        <div className={`flex-col justify-start items-center gap-6 inline-flex mx-8 w-full ` + clsxMerge(boxStyles({theme}))}>
                            <div className='w-full'>
                            <div className={`flex flex-col justify-between border-x border-t ` + clsxMerge(borderStyles({theme})) }>
                                    <div className='font-semibold px-6 pt-6 text-lg'>
                                        General information
                                    </div>
                                    <div className='text-grey-500 px-6 pb-6 text-sm'>
                                        Update name and general information
                                    </div>
                                </div>
                                <div
                                    className={`w-full border px-8 gap-6 grid py-8 ` + clsxMerge(borderStyles({theme}))}>

                                    <div>
                                        <Controller
                                            name="firstName"
                                            control={control}
                                            rules={{required: 'First name is required'}}
                                            render={({field}) => (
                                                <ChInput
                                                    {...field}
                                                    value={field.value}
                                                    callback={(value: any) => field.onChange(value)}
                                                    error={errors.firstName ? errors.firstName.message : undefined}
                                                    className='max-w-full py-3' label='First name'
                                                    />
                                            )}
                                        />
                                    </div>
                                    <div>
                                        <Controller
                                            name="lastName"
                                            control={control}
                                            rules={{required: 'Last name is required'}}
                                            render={({field}) => (
                                                <ChInput
                                                    {...field}
                                                    value={field.value}
                                                    callback={(value: any) => field.onChange(value)}
                                                    error={errors.lastName ? errors.lastName.message : undefined}
                                                    className='max-w-full py-3' label='Last name'
                                                    />
                                            )}
                                        />
                                    </div>
                                    <div>
                                        <Controller
                                            name="email"
                                            control={control}
                                            rules={{required: 'email is required'}}
                                            render={({field}) => (
                                                <ChInput value={field.value}
                                                         disabled
                                                         callback={(value: any) => field.onChange(value)}
                                                         className='max-w-full py-3'
                                                         label='Email adress'
                                                         />
                                            )}
                                        />
                                    </div>
                                    <div>
                                       <Controller
                                       name="position"
                                         control={control}
                                            render={({ field }) => (
                                        <ChInput value={field.value}
                                                callback={(value:any) => field.onChange(value)}
                                                 className='max-w-full py-3 mb-6' label='Position'/>
                                            )}
                                        />
                                    </div>

                                </div>
                            </div>
                        </div>
                    }
                    {
                        Boolean(state === 'GENERAL_INFORMATION' || state === 'PHONE_NUMBERS') &&
                        <div
                            className={`  w-full border-x border-t ` + clsxMerge(borderStyles({theme}), boxStyles({theme}))}>
                            <div className=''>
                                <div
                                    className={`font-semibold px-6 p-6 text-lg border-b ` + clsxMerge(borderStyles({theme}))}>
                                    Assigned phone numbers
                                </div>
                                <div
                                    className={`font-semibold px-6 p-6 text-xs border-b ` + clsxMerge(borderStyles({theme}))}>
                                    Phone numbers
                                </div>
                                <div className='w-full  '>
                                    <div className=' w-full '>
                                        {profileSettings?.msisdns.map((number) => (
                                            <div key={number.msisdnId}
                                                 className={`border-b ` + clsxMerge(borderStyles({theme}))}>
                                                <div className='flex gap-3 text-sm items-center pl-4 py-4'>
                                                    <div >
                                                    <Avatar icon={number.icon} size="xs" type='icon' color={number.iconColor} name=''/>
                                                    </div>
                                                    {number.label}
                                                </div>

                                            </div>
                                        ))}
                                    </div>
                                </div>

                            </div>

                        </div>
                    }
                    {
                        Boolean(state === 'GENERAL_INFORMATION' || state === 'GROUPS') &&
                        <div
                            className={`w-full border-x border-t ` + clsxMerge(borderStyles({theme}), boxStyles({theme}))}>
                            <div className=''>
                                <div
                                    className={`font-semibold px-6 p-6 text-lg border-b  ` + clsxMerge(borderStyles({theme}))}>
                                    Assigned groups
                                </div>
                                <div
                                    className={`font-semibold px-6 p-6 text-xs border-b ` + clsxMerge(borderStyles({theme}))}>
                                    Groups
                                </div>
                                <div className='w-full  '>
                                    <div className=' w-full '>
                                        {profileSettings?.groups.map((group) => (
                                            <div
                                                className={`border-b ` + clsxMerge(borderStyles({theme}))}>
                                                <div className='flex gap-3 text-sm items-center pl-4 py-4'>
                                                    <Avatar size="xs" type='icon'
                                                            color={group.iconColor ?? Colors.auto}
                                                            icon='home'/>{group.name}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                            </div>

                        </div>
                    }
                    {
                        Boolean(state === 'GENERAL_INFORMATION' || state === 'NOTIFICATION') &&
                        <div className='w-full '>
                            <div
                                className={`flex flex-col justify-between border-x border-t ` + clsxMerge(borderStyles({theme}), boxStyles({theme}))}>
                                <div
                                    className={`font-semibold px-6 p-6 text-lg border-b ` + clsxMerge(borderStyles({theme}))}>
                                    Notification
                                </div>

                                <div
                                    className={`w-full border-b  p-6 gap-6 grid items-center ` + clsxMerge(borderStyles({theme}))}>
                                    <div className='my-2'>
                                        <Controller
                                            name="missedCallEmailNotificationEnabled"
                                            control={control}
                                            render={({field}) => (
                                        <Switch size='lg' checked={field.value}
                                                callback={() => handleSwitchChange(field)}
                                                label={'Email me about missed incoming calls'}
                                                alignLeft={false}></Switch>
                                            )}
                                        />

                                    </div>
                                </div>

                            </div>

                        </div>
                    }
                    {/*{*/}
                    {/*    Boolean(state === 'GENERAL_INFORMATION' || state === 'PERSONALIZATION') &&*/}
                    {/*    <div className='w-full '>*/}
                    {/*        <div className={`flex flex-col justify-between border-x border-t ` + clsxMerge(borderStyles({theme}), boxStyles({theme}))}  >*/}
                    {/*            <div className={`font-semibold px-6 p-6 text-lg border-b ` + clsxMerge(borderStyles({theme}))} >*/}
                    {/*            Personalization*/}
                    {/*            </div>*/}

                    {/*            <div className={`w-full border-b  p-6 gap-6 grid items-center ` + clsxMerge(borderStyles({theme}))}>*/}
                    {/*                <div className='my-2 flex items-center justify-between'>*/}
                    {/*                    <div>*/}
                    {/*                        <div>*/}
                    {/*                            Theme*/}
                    {/*                        </div>*/}
                    {/*                        <div>*/}
                    {/*                            Choose your application's color scheme.*/}
                    {/*                         </div>*/}
                    {/*                    </div>*/}
                    {/*                    <ThemeSwitcher></ThemeSwitcher>*/}


                    {/*                </div>*/}
                    {/*            </div>*/}

                    {/*        </div>*/}

                    {/*    </div>*/}
                    {/*}*/}
                </div>


            </div>
            <div className={` flex justify-end `}>
                <div className={`mx-8 mt-8 flex  justify-between`}>

                    <div className='flex gap-4'>
                        <Link to={'/groups'}>
                            <Button size={'large'} label='Cancel' buttonType='secondary'/>
                        </Link>
                        <Button loading={loading} size={'large'} onClick={handleSubmit(onSubmit)} className={'min-w-[175px] max-h-[60px] mb-8'} label='Update settings'
                                buttonType='primary'/>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProfileSettings;
