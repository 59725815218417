import {Colors} from "../../utilis/types/Types";
import {getCallTypeDescription} from "../../utilis/callsUtilis";
import {FlagImage} from "react-international-phone";
import {getFlag, getFormatedPhoneNumber} from "../../utilis/msisdnUtilis";
import logo from "../../assets/logo.svg";
import {Avatar, Icon} from "ch3-ui-lib";
import {Call} from "../../store/features/calls/callsService";
import {useEffect, useState} from "react";
import {formattedTime} from "../../utilis/timeUtilis";

export interface OnGoingCallProps {
    call: Call;
    isMobile: boolean;
    handleCallClick: any;
    selectedCall: any;

}

export const OnGoingCall = ({ call, isMobile, handleCallClick, selectedCall}: OnGoingCallProps) => {

    const [timer, setTimer] = useState(0);

    useEffect(() => {
        let interval: number;

        interval = setInterval(() => {
            setTimer((prevTimer) => prevTimer + 1);
        }, 1000);

        return () => {
            if (interval) {
                clearInterval(interval);
                setTimer(0);
            }
        };
    }, []);

    return (
        <div key={call.callId} onClick={() => handleCallClick(call)}
             className={`flex justify-between rounded py-5 px-8  ${selectedCall == call ? 'bg-grey-50 ' : ''}` + `${isMobile ? ' flex-col' : ' flex-row'} `}>
            <div>
                <div className='flex items-center '>
                    {Boolean(call.primaryContact && !call.internalCallee) &&
                        <>
                            <Avatar size="md" type={call.primaryContact ? 'initials' : 'default'}
                                    color={Colors.lightYellow}
                                    name={call.primaryContact.firstName + ' ' + call.primaryContact.lastName}/>

                            <div className="flex flex-col ml-4">
                                <p className={`text-sm font-medium ${!call.bridgetAt && !call.forwardedAt ? ' text-error-500' : ' '}`}>{call.primaryContact?.firstName + ' ' + call.primaryContact?.lastName} </p>
                                <p className="text-xs text-error-600">{getCallTypeDescription(call.type, call.bridgetAt, call.forwardedTo)} · {formattedTime(timer)}</p>
                            </div>
                        </>

                    }
                    {Boolean(call.internalCallee) &&
                        <>
                            <Avatar size="md" type={call.internalCallee ? 'initials' : 'default'}
                                    color={Colors.lightYellow}
                                    name={call.internalCallee.firstName + ' ' + call.internalCallee.lastName}/>
                            <div className="flex flex-col ml-4">
                                <p className={`text-sm font-medium mb-1 ${!call.bridgetAt && !call.forwardedAt ? ' text-error-500' : ' '}`}>{call.internalCallee && call.internalCallee.firstName + ' ' + call.internalCallee.lastName}</p>
                                <div className="flex">
                                    <div
                                        className="text-xs flex border border-grey-200 h-[20px] rounded-md justify-center items-center p-2">
                                        <Icon iconName="lock" size='xs'
                                              className="p-0 w-4 h-4 text-grey-400"></Icon>
                                        <p className="text-grey-400">Internal</p>
                                    </div>
                                    <p className="text-xs ml-2 text-error-600">{getCallTypeDescription(call.type, call.bridgetAt, call.forwardedTo)} · {formattedTime(timer)}</p>
                                </div>
                            </div>
                        </>
                    }

                    {Boolean(!call.internalCallee && !call.primaryContact) &&
                        <>
                            <Avatar size="md" type={'default'} color={Colors.lightYellow}/>
                            <div className="flex flex-col ml-4">
                                <div className='flex items-center'>
                                    <FlagImage size={16} className='w-3 h-3'
                                               iso2={getFlag(call.clientMsisdn) ?? 'us'}/>
                                    <div
                                        className={`ml-2 text-sm font-medium ${!call.bridgetAt && !call.forwardedAt ? 'text-error-500' : ' '}`}>{getFormatedPhoneNumber(call?.clientMsisdn, true)}</div>
                                </div>
                                <p className="text-xs text-error-600">{getCallTypeDescription(call.type, call.bridgetAt, call.forwardedTo)} · {formattedTime(timer)}</p>
                            </div>

                        </>

                    }


                </div>
            </div>

            <div
                className={`flex` + `${isMobile ? ' flex-col items-start px-2' : ' flex-row items-center px-0 w-1/8 min-w-[195px] justify-end '}`}>
                <div className={'flex items-center '}>

                    <div className='flex items-center ml-auto w-1/10'>

                    </div>
                    <div className='flex  w-1/10  ml-4'>


                        {call.exposedMsisdn && <>
                            <div
                                className="text-[20px] flex mb-[5px]"><Avatar icon={call.exposedMsisdn.icon}
                                                                              size="sm" type='icon'
                                                                              color={call.exposedMsisdn.iconColor}
                                                                              name='phone number icon'/>
                            </div>
                        </>}
                        {
                            call.internalCallee &&
                            <>

                                <div className="">
                                    <img className={'h-[20px] w-[20px] rounded'} src={logo}
                                         alt="PhoneHQ logo"/>

                                </div>
                            </>
                        }


                    </div>
                </div>
            </div>

        </div>
    )
}
