import {useEffect, useState} from 'react';
import {borderStyles} from './helpers';
import {Call} from "../../store/features/calls/callsService";
import {clsxMerge} from '../../utilis/classNameUtils';
import {selectTheme} from "../../store/features/ThemeSwitcher/themeSwitcherSlice";
import {useSelector} from "react-redux";


import {OnGoingCall} from "./onGoingCall";

interface OnGoingCallsProps {
    calls: Call[];
    isMobile: boolean;
}

export default function OnGoingCalls({calls, isMobile}: OnGoingCallsProps) {
    const [isExpanded, setIsExpanded] = useState(false);
    const [ongoingCalls, setOngoingCalls] = useState([] as Call[]);
    const theme = useSelector(selectTheme);
    const [selectedCall, setSelectedCall] = useState();



    useEffect(() => {
        setOngoingCalls(calls);
    }, [calls]);

    const handleExpandClick = () => {
        setIsExpanded(!isExpanded);
    };


    const handleCallClick = (call: any) => {
        setSelectedCall(call == selectedCall ? null : call);



    };

    return (
        <div className=''>
            {ongoingCalls.length > 0 && (
                <div className={`flex justify-between bg-primary-50`}>
                    <div className='px-4 flex items-center cursor-pointer font-semibold' onClick={handleExpandClick}>

                    </div>

                </div>)}

            <div className={` bg-warning-50 ` + clsxMerge(borderStyles({theme}))}>
                {ongoingCalls.map((
                    call: Call
                ) => (
                    <OnGoingCall selectedCall={selectedCall} key={call.callId} call={call} isMobile={isMobile} handleCallClick={handleCallClick}>

                    </OnGoingCall>



                ))}
            </div>

        </div>

    );
}
