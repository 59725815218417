import {FlagImage} from "react-international-phone";
import {getFlag, getFormatedPhoneNumber} from "../../utilis/msisdnUtilis";
import { ChTabs, Avatar} from "ch3-ui-lib";
import {PhoneNumberInfo} from "./PhoneNumberInfo";
import WorkingHours from "./WorkingHours";
import CallRules from "./CallRules";
import useMediaQuery from "../../utilis/screenWidthUtils.ts";
import listEmpty from "../../assets/list-empty.svg";
import appStore from "../../assets/app-store-icon.png";
import googlePlay from "../../assets/google-play-icon.png";
import {useFetchMsisdnSettingsInfoQuery} from "../../store/features/MsisdnSettings/msisdnSettingsService";
import {useParams} from 'react-router-dom';



interface PhoneNumberSettingsProps {
    admin: boolean;
}

const PhoneNumberSettings = ({admin = false}: PhoneNumberSettingsProps) => {

    const tabs = [
        {
            id: '1',
            label: 'Information',
            content: <PhoneNumberInfo admin={admin} />
        },
        {id: '2', label: 'Working hours',  content: <WorkingHours admin={admin} />},
        {id: '3', label: 'Call rules', content: <CallRules admin={admin} />}];

    const {id} = useParams<{ id: string }>();
    const isMobile = useMediaQuery('(max-width: 960px)');
    const { data: msisdn } = useFetchMsisdnSettingsInfoQuery(id as string);
    const headerItems = (
        <div className='flex justify-between  text-xl items-start w-full'>
            <div className='flex items-center text-semibold text-xl'>
                <Avatar className='mr-4' icon={msisdn?.msisdn?.icon} size="sm" type='icon' color={msisdn?.msisdn?.iconColor}
                    name=''/>
                {msisdn?.msisdn?.msisdn && <FlagImage className='mr-1' iso2={getFlag(msisdn?.msisdn?.msisdn)} size={16} />}
                {msisdn?.msisdn?.msisdn && getFormatedPhoneNumber(msisdn?.msisdn?.msisdn, true)}
            </div>
            <div>

            </div>
        </div>
    )
    return (
        <div className='w-full'>

            {Boolean(!isMobile) && 
                        <div>
                            <ChTabs headerItems={headerItems} activeTabId={tabs[0].id} className={`py-[18px] px-8 h-[61px] flex justify-end  border-b  border-black ${isMobile ? ' flex-wrap flex-col' : 'no-wrap flex-row'}`} tabs={tabs}>

                            </ChTabs>
        
                    </div>
            }
            {Boolean(isMobile) && 

            <div className='flex text-center  justify-center border border-black m-8'>
                <div className='w-[450px]  m-14'>
                    <div className='flex  justify-center w-full'>
                        <img className='m-4' src={listEmpty}/>
                    </div>

                    <h5 className='mb-6 mt-2'>
                    Please visit our Mobile App in order to access Phone Number Settings
                    </h5>
                    <div className="flex flex-wrap justify-center items-center">
                        <a href="https://apps.apple.com/us/app/phonehq/id6503608602"><img className='m-4' width="138" src={appStore}/></a>
                        <a href="https://play.google.com/store/apps/details?id=com.phonehq"><img className='m-4' width="138" src={googlePlay}/></a>
                    </div>

                </div>

            </div>
            }
        </div>
            )
}
export default PhoneNumberSettings;
