import {Avatar, ChTooltip} from "ch3-ui-lib";
import {TelcoStatus} from "../../store/features/Team/teamService";
import {convertFromISOFormat} from "../../utilis/timeUtilis";
import {RootState} from "../../store/store";
import {useSelector} from "react-redux";
import {hexToEmoji} from "../../components/Emoji/emoji";
import { borderStyles, boxStyles } from "./helpers.tsx";
import { clsxMerge } from "../../utilis/classNameUtils.ts";
import {useCallService} from "../../services/callService";

const Team = ({user}: any) => {

    const { makeCall } = useCallService();

    const callState = useSelector((state: RootState) => state.dialer.callState);
    const theme = useSelector((state: any) => state.themeSwitcher.theme);

    const { dateString, timeString } = convertFromISOFormat(user?.customUserStatus?.dueDate || '');
    const expires = dateString ? `${dateString}, ${timeString}` : timeString;



    const handleCall = () => {
        if (callState !== 'IDLE') return;

        makeCall({
            msisdn: user.userId,
            fromName: `${user.firstName} ${user.lastName}`,
            isInternal: true,
        });
    };


    const getUserStatus = () =>
        user.doNotDisturbUserStatus.enabled ? TelcoStatus.DND : user.telcoUserStatus.status;

    return (

            <div className={`border m-5 max-w-[424px] min-h-[292px] flex flex-col ` + clsxMerge(borderStyles({theme}), boxStyles({theme}))}>
                <div className="py-7">

                <Avatar className=' px-[130px]' size='xxl'  name={user.firstName + ' ' + user.lastName}  type={'initials'} color={'auto'} status={getUserStatus()}/>

                    <div className=' flex text-base font-semibold justify-center text-xl'>{user.firstName + ' ' + user.lastName}</div>
                        {Boolean(user?.position) &&
                            <div className="flex items-center justify-center">
                            <div className="w-[200px] truncate uppercase text-sm font-semibold text-center text-grey-700 mb-3 mt-1">{user.position}</div>
                            </div>
                        }
                        {Boolean(user?.customUserStatus?.icon) &&
                        <div className="flex items-center justify-center flex-col">
                        <ChTooltip theme={'dark'} alignArrow='start' content={user.customUserStatus.name} trigger={<div className='flex items-center justify-center font-medium w-[180px] truncate'>{hexToEmoji(user.customUserStatus.icon, 'w-4 h-4 mr-2') } <div className="">{user.customUserStatus.name}</div> </div>}></ChTooltip>
                            <div className='text-sm text-grey-500'>
                                {expires ? `Expires : ${expires}` : ''}
                            </div>
                        </div>
                        }</div>
                {Boolean((user.telcoUserStatus.status === TelcoStatus.TALKING || user.telcoUserStatus.status === TelcoStatus.OFFLINE) && !user.doNotDisturbUserStatus.enabled) &&
                    <div className={`border-t p-4 justify-center flex mt-auto ` + clsxMerge(borderStyles({theme}))}>
                    <i className='cursor-pointer bg-warning-400 text-white material-icons-sharp  p-3 rounded-full'>phone_in_talk</i>
                </div>

                }
                                {Boolean(!user.doNotDisturbUserStatus.enabled && user.telcoUserStatus.status !== TelcoStatus.TALKING && user.telcoUserStatus.status !== TelcoStatus.OFFLINE) &&
                    <div className={`border-t p-4 justify-center flex mt-auto ` + clsxMerge(borderStyles({theme}))}>
                    <i  onClick={() => handleCall()} className='cursor-pointer bg-success-500 text-white material-icons-sharp  p-3 rounded-full'>call</i>
                </div>
                }
                                {Boolean(user.doNotDisturbUserStatus.enabled) &&
                    <div className={`border-t  p-4 justify-center flex mt-auto ` + clsxMerge(borderStyles({theme}))}>
                    <i  className='cursor-pointer bg-error-400 text-white material-icons-sharp  p-3 rounded-full'>remove</i>
                </div>
                }

            </div>
    )
}

export default Team;
